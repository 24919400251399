<template>
  <div class="sidenav-section" data-simplebar style="z-index:8">
    <!-- SideNav Inner Start -->
    <div class="sidenav-inner">
      <nav class="sidenav-menu" id="sidenav-menu">
        <ul v-if="!admin_area">
          <li v-for="(elem, index) in path_client " :key="index" :class="{ 'has-sub-menu': elem.children.length != 0 }">
            <router-link v-if="elem.children.length == 0" @click.native="set_active(path_client, index)"
              class="align-items-center" :to="{ name: elem.name }">
              <i v-if="elem.iconType == 'feather'" :data-feather="elem.icon" class="nav-icon"></i>
              <i v-if="elem.iconType == 'fontAwesome'" :class="[elem.icon]" class="nav-icon"></i>
              {{ elem.text }}<span class="arrow"></span>
            </router-link>
            <a :href="elem.url" :class="{ active_dropdown: elem.active }" v-if="elem.children.length != 0"
              @click="toggle_open(path_client, index)"><i :data-feather="elem.icon" class="nav-icon"></i>{{ elem.text
              }}<span class="arrow"><i
                  :class="[{ 'fa fa-angle-down': !elem.open }, { 'fa fa-angle-up': elem.open }]"></i></span></a>
            <transition name="slide" v-if="elem.children.length != 0">
              <ul class="sub-menu" v-show="elem.open">
                <li v-for="child in elem.children" @click="set_active(path_client, index)">
                  <router-link :to="{ name: child.name }">
                    {{ child.text }}
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(elem, index) in path_admin " :key="index" :class="{ 'has-sub-menu': elem.children.length != 0 }">
            <router-link v-if="elem.children.length == 0" @click.native="set_active(path_admin, index)"
              class="align-items-center" :to="{ path: elem.url }">
              <i :data-feather="elem.icon" class="nav-icon"></i>{{ elem.text }}<span class="arrow"></span>
            </router-link>
            <a :href="elem.url" :class="{ active_dropdown: elem.active }" v-if="elem.children.length != 0"
              @click="toggle_open(path_admin, index)"><i :data-feather="elem.icon" class="nav-icon"></i>{{ elem.text
              }}<span class="arrow"><i
                  :class="[{ 'fa fa-angle-down': !elem.open }, { 'fa fa-angle-up': elem.open }]"></i></span></a>
            <transition name="slide" v-if="elem.children.length != 0">
              <ul class="sub-menu" v-show="elem.open">
                <li v-for="child in elem.children" @click="set_active(path_admin, index)">
                  <router-link :to="child.url">
                    {{ child.text }}
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </nav>
    </div>
    <!-- SideNav Inner End -->
  </div>
</template>
<script>
import { feather } from '@/main.js';
import indDB from '@/helpers/IndexedDB'
import {
    mapGetters
} from 'vuex'
export default {
  props: {
  },
  async created() {
    this.InitData();
    let user_id;
    let user_name
    let user_role
   /* await indDB.getUserToken().then(el => { user_id = el.userName; user_role = el.role, user_name = el.name }); if (user_role == "admin") { this.name = "SuperAdmin"; } else {

      if (user_name != null) { this.name = user_name }
      else
        this.$store.dispatch(USERS_BY_GUID_GET_REQUEST, user_id).then(el => {
          this.name = el[0].name; setTimeout(() => {
          }, 500);
        })
    }*/
  },
  watch:{
    'AllIssuedCards':function (new_val,old_val)
    {var ic=this.path_client.find(a=>a.name=='IssuedCards')
    if(ic!=undefined) ic.text=`Новые карты (${new_val.length})`
  }
  },
  computed: {
    admin_area() {
      return this.$route.fullPath.indexOf("admin") > -1
    },
    ...mapGetters(['AllIssuedCards']),
  },
  mounted() {
      feather.replace();
     /*
        $('#sidenav-menu').on('click', 'a', function (e) {
          var $this = $(this);
          if ($this.attr('href') === '#') {
            e.preventDefault();
            if ($this.siblings('.sub-menu:visible').length) {
               $this.parent().removeClass('open').find('.sub-menu').slideUp().parent().removeClass('open');
            } else {
               $this.siblings('.sub-menu').slideDown().parent().addClass('open').siblings().find('.sub-menu').slideUp().parent().removeClass('open');
            }
          }
        });
    */
  },
  data() {
    return {
      path_admin: [],
      path_client:[],
      name: null,
    }
  },
  methods: {
    InitData(){
      var path=this.$route.path;
      this.path_admin= [
        {
          url: "/admin/clients",
          text: "Клиенты",
          icon: 'users',
          iconType: 'feather',
          children: [],
          active:path.indexOf("admin/clients")>-1 ? true : false,
        },
        {
          url: "/admin/cards",
          text: "Отчёт выпуска карт",
          icon: 'credit-card',
          iconType: 'feather',
          children: [],
          active: path.indexOf("admin/cards")>-1 ? true : false,
        },
      ];
      this.path_client=
        [
          {
            name:'Cards',
            url: "/home/vehicles",
            text: "Транспортные средства",
            icon: 'fa-solid fa-car',
            iconType: 'fontAwesome',
            children: [],
            active:  path.indexOf("home/vehicles")>-1 ? true : false,
          },
          {
            name:'IssuedCards',
            url: "/home/issuedCards",
            text: `Новые карты (0)`,
            icon: 'credit-card',
            iconType: 'feather',
            children: [],
            active:  path.indexOf("home/issuedCards")>-1 ? true : false,
          },
          {
            url: "#",
            text: "Терминалы",
            icon: 'server',
            iconType: 'feather',
            children: [
              {
                text: "Конфигурация",
                name:'Terminals',
                url: "terminals"
              
              },
              {
                text: "Терминалы на карте местности",
                name:'Terminals_map',
                url: "terminals_map"
              }
            ],
            active: path.indexOf("home/terminals")>-1 ||  path.indexOf("home/terminals_map")>-1 ? true : false,
            open:  path.indexOf("home/terminals")>-1 ||  path.indexOf("home/terminals_map")>-1 ? true : false,
          },
          
          {
            url: "#",
            text: "Справочники",
            icon: 'book-open',
            iconType: 'feather',
            children: [
              {
                text: "Подразделения",
                name:"Departments",
                url: "directory/departments",
              },
              {
                text: "Водители",
                name:"Drivers",

                url: "directory/drivers"
              },
              {
                name:"Products",
                text: "Продукты",
                url: "directory/products"
              }
            ],
            active: path.indexOf("directory/departments")>-1 ||  path.indexOf("directory/drivers")>-1 ||  path.indexOf("directory/products")>-1 ? true : false,
            open:  path.indexOf("directory/departments")>-1 ||  path.indexOf("directory/drivers")>-1 ||  path.indexOf("directory/products")>-1 ? true : false,
          },    
          {
            name:'Transactions',
            url: "transactions",
            text: "Отчёт",
            icon: 'list',
            iconType: 'feather',
            children: [],
            active:  path.indexOf("home/transactions")>-1 ? true : false,
          },
        ];
    },
    set_active(array, index) {
      setTimeout(() => {
        document.querySelector(".main-wrapper").classList.remove('sidenav-open')
      }, 500);
      for (var i = 0; i < array.length; i++) {
        if (i == index) {
          if (!array[i].active) array[i].active = true;
        }
        else {
          array[i].active = false;
          // if(this.path_client[i].open) this.path_client[i].open=false
        }
      }
    },
    toggle_open(array, index) {
      array[index].open = !array[index].open
    },
    Select: function () {
      if (this.isFolder) { this.$emit('select', this.guid); }
    }
  }
}
</script>
<style scoped>
.active_dropdown,
a.router-link-active,
li.router-link-active>a {
  color: var(--bs-sidenav-link) !important;
}

.sidenav-section {
  position: fixed;
  z-index: 9;
  top: 80px;
  left: 0;
  width: 230px;
  height: calc(100vh - 80px);
  padding: 40px 0;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  background-color: #202442
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .sidenav-section {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  .sidenav-toggle {
    display: block !important;
  }

}

@media only screen and (max-width: 767px) {
  .sidenav-section {
    top: 42px;
    height: calc(100vh - 42px);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  .sidenav-toggle {
    display: block !important;
  }
}

.sidenav-open .sidenav-section {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0)
}

.sidenav-inner {
  max-height: 100%
}

.sidenav-menu {
  padding: 0 30px
}

.sidenav-menu ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none
}

.sidenav-menu ul>li {
  margin-top: 0
}

.sidenav-menu ul>li>a,.sidenav-menu ul>li>div {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  color: #F2F2F2
}

.sidenav-menu ul>li>a .nav-icon {
  width: 18px;
  margin-right: 10px
}

.sidenav-menu ul>li>a .arrow {
  margin-left: auto;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s
}

.sidenav-menu ul>li.open>a {
  color: #89FBFE
}

.sidenav-menu ul>li.open>a .arrow {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg)
}

.sidenav-menu ul .sub-menu {
  padding-left: 30px
}
</style>