<template>
    <div class="content-body">
        <!-- #region PageHeadingsStart -->
        <div class="row align-items-center justify-content-between mb-0">
            <h3><i data-feather="terminal" class="nav-icon"></i>
                <span class="titlePage"> Терминалы </span>
                <div class="btn-group">
                    <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                            class="nav-icon fas fa-sync-alt"></i></button>
                    <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateTerminalModal()"
                        title="Добавить терминал" v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }">
                        <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить терминал
                    </button>
                </div>
            </h3>
        </div>
        <!-- #endregion Page Headings End -->




        <div class="d-flex flex-wrap align-items-center justify-content-between px-0 px-sm-2 py-2" style="gap:5px">
            <data-table-filter v-model="filter" class="order-sm-1 order-2"
                filterText="Поиск терминалов"></data-table-filter>
            <div class="d-flex order-1 order-sm-2 align-items-md-center flex-column flex-md-row" style="gap:5px">
                Сортировать по:
                <multiselect autocomplete="off" class="multiselect-sort" trackBy="text" valueProp="value"
                    :searchable="false" locale="ru" :close-on-select="false" :hideSelected="false" label="text" clear
                    required v-model="sortBy" :options="sortArray" :canClear="false" :canDeselect="false">

                    <template v-slot:nooptions>
                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                            Нет элементов</div>
                    </template>
                    <template v-slot:noresults>
                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                            Не найдено</div>
                    </template>
                </multiselect>
            </div>
        </div>

        <div class="row" v-if="AllTerminalsFiltered.length > 0">
            <div class="col-12 col-xl-3 col-lg-4 col-md-6  col-sm-6  py-1 px-0 px-md-2 "
                v-for="(item, index) in AllTerminalsFiltered" :key="item.id"> <b-overlay :show="TerminalsGetAreLoading"
                    opacity="0.5">
                    <b-card no-body class="terminal border">
                        <template #header>

                            <div class="terminal-header" style="min-height: 21px;">
                                <div class=terminal-header-content style="gap:5px">
                                    <signal v-if="item.online" :title="'Уровень сигнала: ' + item.gsmQuality"
                                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                        :maxQuality="33" :currentValue="item.gsmQuality ?? 0"></signal>
                                    <div class="d-flex align-items-center"
                                        v-b-tooltip="{ placement: 'bottom', variant: 'secondary', html: true }" v-else
                                        :title="OffTime(item.lastConnectionDateTime)">
                                        <b-iconstack scale="1" font-scale="1.5">
                                            <b-icon stacked icon="circle-fill" style="color:#d3d3d3;">
                                            </b-icon>

                                        </b-iconstack>
                                    </div>
                                    <font class="terminal-header-title">{{ item.name }}</font>

                                </div>
                                <div class="d-flex align-items-center" style="gap:5px">
                                    <div class="d-flex align-items-center"
                                        v-b-tooltip.hover="{ placement: 'top', variant: 'warning' }"
                                        title="Конфигурация на изменении">
                                        <b-iconstack v-show="item.isConfigurationChanged" font-scale="1.5">
                                            <b-icon icon="border-style" stacked variant="warning" scale="0.5"></b-icon>
                                            <b-icon stacked icon="circle" variant="warning "></b-icon>
                                        </b-iconstack>
                                    </div>
                                    <div class="actions" style=" cursor:pointer">
                                        <button @click="ShowEditModalTerminal(item.id)" class="btn  change"
                                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                            v-if="!item.isRemoved" style="float:right" :title="`Свойства и конфигурация терминала; \n                                            
                                                        Версия ПО: ${GetSoftwareVersion(item.id)}
`
                                                "><i class="fa fa-cog fa-2x"></i></button>
                                    </div>
                                </div>
                            </div>






                        </template>
                        <b-card-body class="d-flex flex-column terminal-body" style="gap:5px">

                            <div class="d-flex justify-content-between align-items-center terminal-text">
                                <span class="badge rounded-pill bg-primary"
                                    :class="{ 'bg-success': item.online, 'bg-secondary': !item.online }"
                                    @click="ToggleStatus(item)" style="cursor:pointer">Статус</span>
                                <span v-if="GetSoftwareVersion(item.id) > 0" style="font-size: 1rem;">v.{{
                                    GetSoftwareVersion(item.id) }}</span>
                                <transition name="fadeln" mode="out-in">

                                    <div class="position-absolute terminal__status"
                                        v-if="showStatuses?.find(a => a.id == item.id)?.showStatus ?? false">
                                        <div class="d-flex align-items-center justify-content-between" style="gap:5px">
                                            <div class="h5">Cтатус
                                                <hr class="m-0">
                                            </div>
                                            <div class="fst-italic"></div><button
                                                class="btn btn-sm btn-outline-danger btn-smx"
                                                @click="ToggleStatus(item)">Закрыть</button>
                                        </div>
                                        <div style="font-size: 0.9rem;" v-if="item.status != null">
                                            <div class="fst-italic">Дата получения: {{
                                                momentFormat(item.lastConnectionDateTime) }}</div>
                                            <div v-for="(statusField, index) in GetStatus(item.status)" :key="index">{{
                                                statusField.key }} : <span v-html="statusField.value" /> </div>
                                        </div>
                                        <div class="text-center position-relative" style="top:50px; font-size: 1rem;"
                                            v-else>Нет
                                            данных
                                        </div>
                                    </div>
                                </transition>
                            </div>

                            <b-row class="terminal-body__row terminal-body__row--uppercase terminal-text">
                                <div class="col p-1">
                                    <div>Кол-во ТРК</div>
                                    <div class="d-flex justify-content-center " style="font-size:1.5rem; ">
                                        {{
                                            item.terminalConfiguration.pumps.length
                                        }}
                                    </div>
                                </div>

                                <div class="col p-1" style="max-width: calc(100% / 3);">
                                    <div>Протокол</div>
                                    <div class="t-o-ellipsis fst-itali" style="font-size:1rem; ">
                                        {{ GetType(item.terminalConfiguration.pumps[0].type) }}
                                    </div>
                                </div>
                                <div class="col p-1">
                                    <div>Доза ПБ.</div>
                                    <div class="d-flex justify-content-center " style="font-size:1rem; ">
                                        {{
                                            GetFullTankVolume(item.terminalConfiguration.fullTankVolume)
                                        }}
                                    </div>
                                </div>
                            </b-row>

                            <!--Подразделения, Водители, Блок/Карты-->
                            <b-row
                                class="terminal-body__row terminal-body__row--uppercase terminal-body__row--break-all terminal-text">
                                <div class="col p-1 terminal-btn" @click="ShowEditOrganizationsModal(item)"
                                    title="Список подразделений">
                                    <div class="circle"></div>
                                    <div>
                                        Подразделения
                                    </div>
                                    <div class="d-flex text-center " style="font-size:1.5rem;  ">
                                        <div class="align-self-center flex-grow-1">
                                            {{ item.organizationIds?.length }}
                                        </div>
                                        <div v-b-tooltip.hover="{ placement: 'top', variant: 'info' }"
                                            title="Список подразделений ожидает изменения"
                                            v-if="item.isOrganizationChanged">
                                            <b-icon variant="info" icon="house" />
                                        </div>
                                        <div v-else> <b-icon icon="house"></b-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="col p-1 terminal-btn" @click="ShowEditBlocklistModal(item)"
                                    title="Чёрный список карт">
                                    <div>
                                        Блок. карты
                                    </div>
                                    <div class="d-flex text-center" style="font-size:1.5rem; ">
                                        <div class="align-self-center" style="flex:1 1 auto">
                                            {{ item.blackList?.length }}
                                        </div>
                                        <div v-if="item.isBlackListChanged"
                                            v-b-tooltip.hover="{ placement: 'top', variant: 'danger' }"
                                            title="Спискок заблокированных карт ожидает изменения">
                                            <b-iconstack>
                                                <b-icon icon="credit-card2-front" variant="danger" stacked></b-icon>
                                                <b-icon stacked icon="slash" variant="danger" scale="1.5"></b-icon>
                                            </b-iconstack>
                                        </div>
                                        <div v-else>
                                            <b-iconstack>
                                                <b-icon icon="credit-card2-front" stacked></b-icon>
                                                <b-icon stacked icon="slash" scale="1.5"></b-icon>
                                            </b-iconstack>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.terminalConfiguration?.authorizeType ?? false"
                                    class="col p-1 terminal-btn" @click="ShowDriversTerminalModal(item)"
                                    title="Cписок водителей">
                                    <div>
                                        Водители
                                    </div>
                                    <div class="d-flex text-center " style="font-size:1.5rem; ">
                                        <div class="align-self-center" style="flex:1 1 auto">
                                            {{ item.driverList?.length }}</div>
                                        <div v-b-tooltip.hover="{ placement: 'top', variant: 'info' }"
                                            title="Список водителей ожидает изменения" v-if="item.isDriverListChanged">
                                            <b-icon variant="info" icon="person-lines-fill" />
                                        </div>
                                        <div v-else> <b-icon icon="person-lines-fill"></b-icon>
                                        </div>
                                    </div>
                                </div>

                            </b-row>

                            <b-row class="terminal-body__row  terminal-text">
                                <div class="col p-1">
                                    <h6 class="d-flex align-items-center justify-content-between h6 p-2 m-0">Уровнемеры

                                        <i v-if="item.terminalConfiguration.gaugeChannels != null && item.terminalConfiguration.gaugeChannels?.length != 0"
                                            v-b-toggle="item.id + 'gagugeCollapse'" class="fa fa-bars"
                                            style="cursor: pointer;" />
                                        <div class="fst-italic" v-else>Не заданы</div>

                                    </h6>
                                    <b-collapse
                                        v-if="item.terminalConfiguration.gaugeChannels != null && item.terminalConfiguration.gaugeChannels?.length != 0"
                                        :id="item.id + 'gagugeCollapse'">
                                        <b-tabs content-class="px-2" v-model="item.active_tab">
                                            <b-tab v-for="(elem, index) in item.terminalConfiguration.gaugeChannels"
                                                :key="index"
                                                :title="index == item.active_tab ? `${GaugeHaveAddr(item?.terminalConfiguration?.gaugeType) ? 'Канал ' : 'Уровнемер '} ` + (index + 1).toString() : (index + 1).toString()"
                                                :active="index == 0">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div>

                                                            Статус: <span v-if="!LevelGaugeLinkStatus(item, index)"
                                                                class="text-danger">Не
                                                                активен</span>
                                                            <span v-else class="text-success">Активен</span>

                                                        </div>
                                                        <div>

                                                            Тип датчика: <font>
                                                                {{
                                                                    AllGaugeTypesSelect[item.terminalConfiguration.gaugeType]?.text
                                                                }}
                                                            </font>
                                                        </div>
                                                        <div>
                                                            Тип топлива: <font class="badge rounded-pill bg-secondary">
                                                                {{
                                                                    ProductByhardwareID(elem.productID)?.hardwareName
                                                                }}</font>

                                                        </div>
                                                    </div>
                                                    <div class="actions justify-content-center" style="gap:5px">
                                                        <button
                                                            @click="ShowChartsModal(item.id, item.active_tab, item.deserializedGaugeData != null ? item.deserializedGaugeData[item.active_tab] : [], item?.terminalConfiguration?.gaugeChannels[item.active_tab]?.productID ?? 0)"
                                                            class="btn edit" style="gap:5px" v-if="!item.isRemoved">
                                                            График <i class="fa fa-line-chart fa-1x" title="График"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </b-collapse>
                                </div>
                            </b-row>

                        </b-card-body>
                    </b-card> </b-overlay>

            </div>

        </div>
        <div class="row justify-content-center" v-else>
            <div v-if="!TerminalsGetHaveError && !OrganizationsGetHaveError" class="text-center my-2">
                <span v-if="filter.length > 2">Не найдено</span>
                <span v-else> {{ emptyText }}</span>
            </div>
            <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
        </div>




        <!--#region modals  -->
        <!--Модальное окно уровнемера-->

        <bs-modal haveLoading centered id="ChartsModal"
            :title="TerminalByGUID(id)?.name + ', ' + `${GaugeHaveAddr(TerminalByGUID(id)?.terminalConfiguration?.gaugeType) ? 'канал ' : 'уровнемер '}` + parseInt(active_tab + 1)"
            ref="ChartsModal" modal-lg hide-footer>
            <div>
                <h5>Тип топлива: {{ ProductByhardwareID(product_HardwareID)?.hardwareName }}</h5>
                <h5
                    v-if="currentGaugeData?.volume != null || currentGaugeData?.density != null || currentGaugeData?.weigth != null || currentGaugeData?.temperature != null">
                    Текущие показатели:</h5>
                <div v-if="currentGaugeData?.volume != null">Объём продукта: {{
                    currentGaugeData?.volume.toFixed(3) }}
                    л.</div>
                <div v-if="currentGaugeData?.density != null">Плотность продукта: {{
                    currentGaugeData?.density.toFixed(3) }}
                    кг/м<sup>3</sup>.</div>
                <div v-if="currentGaugeData?.weigth != null">Вес продукта: {{
                    currentGaugeData?.weigth.toFixed(3)
                }} кг.
                </div>
                <div v-if="currentGaugeData?.temperature != null">Температура: {{ currentGaugeData?.temperature
                    }}
                    ℃.
                </div>
            </div>
            <hr>
            <h5>История изменения топлива в резервуаре</h5>
            <div class="row align-items-center"><span class="col-4">Интервал отображения:</span>
                <div class="col-8">
                    <bs-datarange @UpdateDT="setDateTime" id="TankHistory" :start.sync="momentStart" :end.sync="momentEnd"
                        required />

                </div>
            </div>
            <div id="chart">
                <apexchart :options="ChartOptions" :series="series"></apexchart>
            </div>

        </bs-modal>
        <!--Модальное водителей-->

        <bs-modal width="400" centered id="DriversTerminalModal" :modal-dialog-scrollable="true" :loading="loading"
            ref="DriversTerminalModal" noDefaultTitle :title="'Список водителей'" :hideFooter="Drivers.length == 0">

            <template #title>
                <div class="modal-title">
                    <div class="h5">Список водителей</div>
                    <div class="fst-italic">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                </div>
            </template>


            <form ref="DriversTerminalForm" id="DriversTerminalForm" @submit.prevent="EditDriversList">
                <div v-if="Drivers.length > 0">
                    <div class="row mb-2 px-3">
                        <div class="col-12">
                            <div class="input-group">
                                <input type="search" class="form-control" placeholder="Поиск..." v-model="filterDrivers"
                                    aria-describedby="Поиск водителей">
                                <div class="input-group-text">
                                    <b-icon icon="search"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center px-3">
                        <div class="col-12">

                            <b-form-checkbox :disabled="filterDrivers.length > 0" plain v-model="allDriverSelected"
                                @change="toggleAllDrivers" :indeterminate="indeterminateDriver">Все
                                водители</b-form-checkbox>

                        </div>

                    </div>
                    <hr class="mx-3">
                    <div class="row align-items-center px-3">
                        <div class="col-12" style="max-height: 350px; overflow-y: auto;">
                            <b-form-checkbox-group v-if="FilteredDrivers.length > 0" size="lg" plain
                                v-model="selectedDrivers" :options="FilteredDrivers"  value-field="id"
                                name="DriversList" stacked>
                            </b-form-checkbox-group>
                            <span v-else>Не найдено</span>
                        </div>
                    </div>
                </div>
                <div v-else class="сol mb-2 px-3 text-center">
                    <div> Cписок водителей пуст</div>
                </div>
            </form>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" form="DriversTerminalForm" type="submit"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>


        <!--Модальное заблокированных карт-->
        <bs-modal width="400" centered id="EditBlocklistModal" :modal-dialog-scrollable="modalDialogScrollable"
            :loading="loading" ref="EditBlocklistModal" noDefaultTitle :title="'Список заблокированных карт'">
            <template #title>
                <div class="modal-title">
                    <div class="h5">Список заблокированных карт</div>
                    <div class="fst-italic">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                </div>
            </template>


            <form ref="EditBlocklistForm" id="EditBlocklistForm" @submit.prevent="DeleteBlackCards()">
                <div v-if="BlackListCards(id).length != 0">
                    <div class="row mb-2 px-3">
                        <div class="col-12">
                            <div class="input-group">
                                <input type="search" class="form-control" placeholder="Поиск..."
                                    v-model="filterBlackList" aria-describedby="Поиск водителей">
                                <div class="input-group-text">
                                    <b-icon icon="search"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mx-3">
                    <div class="col-12 px-3" style="max-height: 350px; overflow-y: auto;">

                        <b-form-group v-if="FilteredBlackList.length > 0" v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group :required="selected_blockCards.length === 0"
                                v-model="selected_blockCards" :options="FilteredBlackList" plain
                                :aria-describedby="ariaDescribedby" name="BlockList" stacked>
                            </b-form-checkbox-group>
                        </b-form-group>
                        <span v-else>Не найдено</span>

                    </div>
                </div>
                <div v-else class="сol mb-2 px-3 text-center">Список
                    заблокированных карт
                    пуст
                </div>
            </form>
            <template #footer>

                <div class="d-flex " style="gap:5px">
                    <button :disabled="loading || selected_blockCards.length != 0" type="button"
                        @click="ShowCreateBlockListModal()"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <i class="fa fa-plus me-1"></i>
                        <span>карты</span>
                    </button>
                    <button v-if="BlackListCards(id).length != 0" form="EditBlocklistForm"
                        :disabled="loading || selected_blockCards.length === 0" type="submit"
                        class="btn btn-sm btn-danger text-center align-items-center">
                        <i class="fa fa-trash me-1"></i>
                        <span>выбранное</span>
                    </button>
                </div>
            </template>

        </bs-modal>
        <!--Модальное добавления заблокированных карт-->
        <bs-modal width="500" centered id="CreateBlocklistModal" :loading="loading" ref="CreateBlocklistModal"
            title="Добавление новык карт к списку заблокированных" noDefaultTitle>
            <template #title>
                <div class="modal-title">
                    <div class="h5">Добавление заблокированных карт</div>
                    <div class="fst-italic">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                </div>
            </template>
            <form ref="CreateBlocklistForm" id="CreateBlocklistForm" @submit.prevent="AddBlackCards">
                <div class="row align-items-center">
                    <div class="col-4">
                        <label class="form-label">Карта</label>
                    </div>
                    <div class="col-8">
                        <!-- <select2 v-model="selected_cards" :options="NoBlockCardsSelect"
                            multiple placeholder="Выберите карту" required id="organizacardtions" />-->
                        <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true" locale="ru"
                            :allowEmpty="true" placeholder="Выберите карту" ref="organizacardtions"
                            id="organizacardtions" :close-on-select="false" mode="tags" :hideSelected="false"
                            maxHeight.number=300 label="text" required v-model="selected_cards"
                            :options="NoBlockCardsSelect">

                            <template v-slot:nooptions>
                                <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                    Нет элементов</div>
                            </template>
                            <template v-slot:noresults>
                                <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                    Не найдено</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </form>
            <template #footer>
                <div class="col-12  text-start">
                    <button :disabled="loading" type="submit" form="CreateBlocklistForm"
                        class="btn btn-primary text-center btn-sm align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--Модальное удаления заблокированных карт-->
        <bs-modal width="400" centered id="DeleteBlockListModal" ref="DeleteBlockListModal" hide-footer
            title="Удалить из чёрного списка">
            <div class="col-12 mb-6">
                <form ref="form" @submit.prevent="DeleteBlackCards">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: justify; text-justify: inter-word;">
                                Вы
                                действительно хотите удалить выбранные карты из чёрного списка?</p>
                        </div>
                        <div class="col-12 mb-2 text-center">
                            <button :disabled="loading" type="submit"
                                class="btn btn-primary text-center align-items-center">
                                <span v-if="!loading">Да</span>
                                <span v-else>
                                    <span class="spinner-border spinner-border-sm mr-1" role="status"
                                        aria-hidden="true"></span>
                                    <span>&nbsp; Загрузка...</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </bs-modal>
        <!--Модальное подразделений карт-->
        <bs-modal width="400" centered id="EditOrganizationsModal" :modal-dialog-scrollable="modalDialogScrollable"
            :loading="loading" ref="EditOrganizationsModal" noDefaultTitle :title="'Cписок подразделений'">

            <template #title>
                <div class="modal-title">
                    <div class="h5">Список подразделений</div>
                    <div class="fst-italic">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                </div>
            </template>
            <form ref="EditOrganizationsForm" id="EditOrganizationsForm" @submit.prevent="DeleteOrganizations()">
                <div v-if="OrganizationsTerminals(id).length != 0">

                    <div class="row mb-2 px-3">
                        <div class="col-12">
                            <div class="input-group">
                                <input type="search" class="form-control" placeholder="Поиск..."
                                    v-model="filterOrganizations" aria-describedby="Поиск водителей">
                                <div class="input-group-text">
                                    <b-icon icon="search"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mx-3">
                    <div class="col-12 px-3" style="max-height: 350px; overflow-y: auto;">

                        <b-form-group v-if="FilteredOrganizations.length > 0" v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group plain :required="selected_organizationsIds.length === 0"
                                v-model="selected_organizationsIds" :options="FilteredOrganizations"
                                :aria-describedby="ariaDescribedby" name="OrganizationsIds" stacked>
                            </b-form-checkbox-group>
                        </b-form-group>
                        <span v-else>Не найдено</span>

                    </div>
                </div>
                <div v-else class="сol mb-2 px-3 text-center">Список подразделений пуст</div>
            </form>
            <template #footer>
                <div class="d-flex" style="gap:5px">
                    <button :disabled="loading || selected_organizationsIds.length != 0" type="button"
                        @click="ShowAddOrganizationsModal()"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <i class="fa fa-plus me-1"></i>
                        <span>подразделение</span>
                    </button>
                    <button v-if="OrganizationsTerminals(id).length != 0"
                        :disabled="loading || selected_organizationsIds.length === 0" type="submit"
                        form="EditOrganizationsForm" class="btn btn-sm btn-danger text-center align-items-center">
                        <i class="fa fa-trash me-1"></i>
                        <span>выбранное</span>
                    </button>
                </div>
            </template>

        </bs-modal>
        <!--Модальное добавления заблокированных карт-->
        <bs-modal width="500" centered id="AddOrganizationsModal" :loading="loading" ref="AddOrganizationsModal"
            title="Добавление новых подразделений к списку обслуживаемых терминалом" noDefaultTitle>
            <template #title>
                <div class="modal-title">
                    <div class="h5">Добавление подразделений</div>
                    <div class="fst-italic">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                </div>
            </template>
            <form ref="AddOrganizationsForm" id="AddOrganizationsForm" @submit.prevent="AddOrganizations">
                <div class="row align-items-center">
                    <div class="col-4 align-self-center">
                        <label class="form-label">Подразделения</label>
                    </div>
                    <div class="col-8 align-self-center">
                        <!--<select2 v-model="selected_organizations" :options="NoOrganizationsSelect"
                            multiple placeholder="Выберите орагнизацию" required id="organizations" />-->
                        <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true" locale="ru"
                            :allowEmpty="true" placeholder="Выберите подразделения" id="organizations"
                            :close-on-select="false" mode="tags" :hideSelected="false" label="text" required
                            v-model="selected_organizations" :options="NoOrganizationsSelect">
                            <template v-slot:nooptions>
                                <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                    Нет элементов</div>
                            </template>
                            <template v-slot:noresults>
                                <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                    Не найдено</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </form>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" type="submit" form="AddOrganizationsForm"
                        class="btn btn-primary btn-sm  text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--Модальное удаления заблокированных карт-->
        <bs-modal width="400" centered id="DeleteOrganizationsModal" ref="DeleteOrganizationsModal"
            title="Удалить из списка подразделений">
            <div class="col-12 mb-6">
                <form ref="form" @submit.prevent="DeleteOrganizations">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: justify; text-justify: inter-word;">
                                Вы
                                действительно хотите удалить выбранные подразделения из чёрного списка?</p>
                        </div>
                        <div class="col-12 mb-2 text-center">
                            <button :disabled="loading" type="submit"
                                class="btn btn-primary text-center align-items-center">
                                <span v-if="!loading">Cохранить</span>
                                <span v-else>
                                    <span class="spinner-border spinner-border-sm mr-1" role="status"
                                        aria-hidden="true"></span>
                                    <span>&nbsp; Загрузка...</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </bs-modal>
        <!---Созд/изм терминала-->
        <bs-modal v-if="terminalConfiguration != null" centered id="CreateEditTerminalModal" modalLg
            :modal-dialog-scrollable="modalDialogScrollable" noDefaultTitle :loading="loading"
            ref="CreateEditTerminalModal" :title="edit ? 'Свойства терминала' : 'Новый терминал'">

            <template #title>
                <div class="modal-title">
                    <div class="h5">{{ edit ? 'Свойства терминала' : 'Новый терминал' }}</div>
                    <div class="fst-italic" v-if="edit">IMEI: <span class="fst-normal"> {{ TerminalByGUID(id)?.imei
                            }}</span>
                    </div>
                    <div class="fst-italic" v-if="imei">Версия ПО: <span class="fst-normal"> {{ softwareVersion > 0 ?
                        softwareVersion : 'Не определена' }}</span>
                    </div>
                </div>

            </template>
            <form ref="CreateOrEditTerminalForm" id="CreateOrEditTerminalForm" class="CreateOrEditTerminalForm"
                @submit.prevent="CreateOrEditTerminal">
                <div class="row align-items-center">
                    <fieldset>
                        <legend>Общие параметры</legend>

                        <div class="row mb-2">
                            <div class="col-sm-5 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Наименование</label></div>
                            <div class="col-sm-7 col-12 align-self-center"><input type="text"
                                    placeholder="Наименование терминала" :disabled="loading" v-model="name"
                                    class="form-control" required />
                            </div>
                        </div>
                        <div class="row mb-2" v-if="!edit">
                            <div class="col-sm-5 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">IMEI</label></div>
                            <div class="col-sm-7 col-12 align-self-center"><input type="text" @keypress="onlyNumber"
                                    :disabled="loading || edit" pattern="[0-9]{15}" title="15 цифр" maxlength="15"
                                    placeholder="xxxxxxxxxxxxxxx" v-model="imei" class="form-control" required />
                            </div>
                        </div>
                        <div class="row mb-2" v-if="!edit || TerminalByGUID(id)?.organizationIds.length == 0">
                            <div class="col-sm-5 col-12  align-self-center"><label
                                    class="form-label font-weight-bold">Подразделения</label></div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true"
                                    locale="ru" :allowEmpty="true" placeholder="Выберите подразделения"
                                    id="organizacardtions_configuration" mode="tags" :close-on-select="true"
                                    label="text" required v-model="selected_organizations"
                                    :options="AllOrganizationSelect">
                                    <!--<template v-slot:beforelist>
                                                        <div class="m-1"><button type="button"
                                                                class="btn btn-outline-success btn-sm btn-add"
                                                                @click="ShowСreateOrganizationModal()"
                                                                title="Добавть подразделение">
                                                                <i class="nav-icon fa fa-plus me-1"
                                                                    aria-hidden="true"></i>Добавить подразделение
                                                            </button></div>
                                        </template>-->

                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Нет элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Не найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-5 col-12 align-self-center">
                                <label class="form-label">Часовой пояс</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <!--<select2 v-model="selected_organizations" :options="NoOrganizationsSelect"
                        multiple placeholder="Выберите орагнизацию" required id="organizations" />-->
                                <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true"
                                    locale="ru" :canClear="false" :canDeselect="false" :allowEmpty="true"
                                    placeholder="Выберите часовой пояс" id="time_zone" :close-on-select="true"
                                    label="text" required v-model="terminalConfiguration.hourOffset"
                                    :options="TimeZones">
                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Нет элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Не найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="CheckVisibleSoftwareParam20">
                            <div class="col-sm-5 col-12 align-self-center">
                                <label class="form-label">Поворот клавиатуры</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <!--<select2 v-model="selected_organizations" :options="NoOrganizationsSelect"
                        multiple placeholder="Выберите орагнизацию" required id="organizations" />-->
                                <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true"
                                    locale="ru" :canClear="false" :canDeselect="false" :allowEmpty="true"
                                    placeholder="Поворот клавиатуры по часовой стрелке" id="keyboard_rotate"
                                    :close-on-select="true" label="text" required
                                    v-model="terminalConfiguration.keyboardRotation" :options="KeyboardRotate">
                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Нет элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Не найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="CheckVisibleSoftwareParam20">
                            <div class="col-sm-5 col-12 align-self-start">
                                <label class="form-label">Предложение ввода номера</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <!--<select2 v-model="selected_organizations" :options="NoOrganizationsSelect"
                        multiple placeholder="Выберите орагнизацию" required id="organizations" />-->
                                <div class="form-check form-switch">
                                    <input class="form-check-input" v-model="terminalConfiguration.useCarNumber"
                                        type="checkbox" :disbled="loading">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="CheckVisibleSoftwareParam25">
                            <div class="col-sm-5 col-12 align-self-start">
                                <label class="form-label">Двойная авторизация</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" v-model="terminalConfiguration.authorizeType"
                                        type="checkbox" :disbled="loading">
                                </div>
                                <div>
                                    <ul class="m-0">
                                        <li><span class="text-warning">Внимание!</span> При активации данного параметра
                                            необходимо настраивать список водителей</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2" v-if="CheckVisibleSoftwareParam25">
                            <div class="col-sm-5 col-12 align-self-start">
                                <label class="form-label">Пароль администратора</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <input class="form-control" @keypress="onlyNumber" placeholder="xxxxxx"
                                    pattern="^[0-9]{6}$" v-model="terminalConfiguration.adminPassword" type="text"
                                    maxlength="6" :disbled="loading" required>

                            </div>
                        </div>

                        <div class="row mb-2" v-if="CheckVisibleSoftwareParam20">
                            <div class="col-sm-5 col-12 align-self-center">
                                <label class="form-label">Время отображения статуса завершения
                                    (сек.)</label>
                            </div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <div class="form-group">
                                    <input type="number" @keypress="onlyNumber"
                                        :disabled="loading || resultTimeoutInfinity" class="form-control" min="0"
                                        :max="hexToDec('FFFFFFFF')" v-model="terminalConfiguration.showResultTimeout"
                                        :disbled="loading">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-model="resultTimeoutInfinity"
                                            id="showResultTimeoutCheck">
                                        <label class="form-check-label" for="showResultTimeoutCheck">
                                            Бесконечно
                                        </label>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-sm-5 col-12  align-self-center"><label
                                    class="form-label font-weight-bold">Доза
                                    полного бака (л.)</label>
                            </div>
                            <div class="col-sm-7 align-self-center"><input type="number"
                                    v-model.number="terminalConfiguration.fullTankVolume" min="10" max="999"
                                    @keypress="onlyNumber" :disabled="loading" class="form-control" required />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Конфигурация ТРК</legend>
                        <div class="row mb-2">
                            <div class="col-sm-5 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Тип
                                    ТРК</label></div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <!-- <bs-select v-model="terminalConfiguration.pumps[0].type" :disabled=" loading" @input="change_type_terminal" :options="AllPumpsTypesSelect" required>
                                <option selected value=""   class="d-none">
                                    Выберите
                                    тип ТРК</option>
                            </bs-select>-->
                                <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true"
                                    locale="ru" :allowEmpty="true" placeholder=" Выберите тип ТРК" id="type_of_TRK"
                                    :canClear="false" :canDeselect="false" :close-on-select="true" label="text"
                                    @input="change_type_terminal" required v-model="terminalConfiguration.pumps[0].type"
                                    :options="AllPumpsTypesSelect">
                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Нет элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Не найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-5 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Кол-во
                                    ТРК</label></div>
                            <div class="col-sm-7 col-12 align-self-center"><input type="number" @keypress="onlyNumber"
                                    min="1" max="4" :disabled="loading" :value="terminalConfiguration.pumps_col"
                                    @input="change_pumps_col" class="form-control" required />
                            </div>
                        </div>
                        <div class="mb-2 d-flex flex-column gap-1" style="">
                            <b-card v-for="elem in terminalConfiguration.pumps_col" :key="elem"
                                style="border: 1px solid #E2E2F3;" no-body>
                                <template #header>
                                    <b-row class="align-items-baseline justify-content-end gy-1">
                                        <div class="col-4" v-b-toggle="'numb' + elem" style="cursor:pointer">
                                            <h5>ТРК №{{ elem }}</h5>
                                        </div>
                                        <div class="col-6 text-end"
                                            v-if="PumpsTypeHaveAddr(terminalConfiguration.pumps[0].type)">
                                            <label class="form-label font-weight-bold p-2"
                                                v-b-toggle="'numb' + elem">Адрес ТРК</label>
                                        </div>
                                        <div class="col-2"
                                            v-if="PumpsTypeHaveAddr(terminalConfiguration.pumps[0].type)">
                                            <input type="number" min="1" @keypress="onlyNumber"
                                                v-model="terminalConfiguration.pumps[elem - 1].addr" :disabled="loading"
                                                class="form-control" required />
                                        </div>


                                        <div class="col text-end"><label class="form-label font-weight-bold p-2"
                                                v-b-toggle="'numb' + elem">Кол-во рукавов</label>
                                        </div>
                                        <div class="col-3 col-sm-2"><input type="number" min="1" max="4"
                                                @keypress="onlyNumber" @input="change_nozzles_col($event, elem - 1)"
                                                :value="terminalConfiguration.pumps[elem - 1].nozzles_col"
                                                :disabled="loading" class="form-control" required />
                                        </div>
                                    </b-row>
                                </template>
                                <b-collapse :id="'numb' + elem" :visible="'numb' + elem === 'numb1'"
                                    accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="p-1 ">
                                        <div class="d-flex flex-column">
                                            <div
                                                v-for="(numb, index) in terminalConfiguration.pumps[elem - 1].nozzles_col">
                                                <div class="row mb-2 align-items-center">
                                                    <div class="col-12 text-start py-1"><b
                                                            style="border-bottom: 0.5px solid #404040">Рукав
                                                            {{
                                                                numb
                                                            }}</b>
                                                    </div>
                                                </div>
                                                <div class="row mb-2 align-items-center">
                                                    <div class="col-sm-5 col-4  mb-2 align-self-center">
                                                        <label class="form-label font-weight-bold">Адрес
                                                            рукава</label>
                                                    </div>
                                                    <div class="col-sm-7 col-8 align-self-center">
                                                        <input type="number" min="1" @keypress="onlyNumber"
                                                            v-model="terminalConfiguration.pumps[elem - 1].nozzles[numb - 1].adr"
                                                            :disabled="loading" class="form-control" required />
                                                    </div>
                                                </div>
                                                <div class="row mb-2 align-items-center">
                                                    <div class="col-sm-5 col-4  mb-2 align-self-center">
                                                        <label class="form-label font-weight-bold">Тип
                                                            ГСМ</label>
                                                    </div>
                                                    <div class="col-sm-7  col-8 align-self-center">
                                                        <multiselect autocomplete="off" trackBy="text" valueProp="value"
                                                            :disabled="loading" :searchable="true" locale="ru"
                                                            :allowEmpty="true" placeholder="Выберите продукт"
                                                            id="Product" :close-on-select="true" label="text"
                                                            :required="'numb' + elem === current_collapse"
                                                            v-model="terminalConfiguration.pumps[elem - 1].nozzles[numb - 1].productID"
                                                            :options="AllProductSelect">
                                                            <template v-slot:nooptions>Нет
                                                                элементов</template>
                                                            <template v-slot:noresults>
                                                                <div
                                                                    class="d-flex w-100 align-items-center justify-content-center my-2">
                                                                    Не найдено</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <hr
                                                    v-if="index != (parseInt(terminalConfiguration.pumps[elem - 1].nozzles_col) - 1)">
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Конфигурация Уровнемера</legend>
                        <div class="row mb-2">
                            <div class="col-sm-5 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Тип
                                    уровнемера</label></div>
                            <div class="col-sm-7 col-12 align-self-center">
                                <!-- <bs-select v-model="terminalConfiguration.pumps[0].type" :disabled=" loading" @input="change_type_terminal" :options="AllPumpsTypesSelect" required>
                                <option selected value=""   class="d-none">
                                    Выберите
                                    тип ТРК</option>
                            </bs-select>-->
                                <multiselect autocomplete="off" trackBy="text" valueProp="value" :searchable="true"
                                    locale="ru" :allowEmpty="true" placeholder="Выберите тип уровнемера"
                                    id="type_of_dut" :close-on-select="true" label="text" required :canClear="false"
                                    openDirection="top" :canDeselect="false" @input="change_gauge_type"
                                    v-model="terminalConfiguration.gaugeType" :options="AllGaugeTypesSelect">
                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Нет элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                            Не найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="row mb-2"
                            v-if="terminalConfiguration.gaugeType > 0 && GaugeHaveAddr(terminalConfiguration?.gaugeType)">
                            <div class="col-5 mb-2 align-self-center"><label class="form-label font-weight-bold">Адрес
                                    уровнемера</label></div>
                            <div class="col-7 align-self-center"><input type="number" @keypress="onlyNumber" min="1"
                                    :disabled="loading" v-model="terminalConfiguration.gaugeAddr" class="form-control"
                                    required />
                            </div>
                        </div>
                        <div class="row mb-2" v-if="terminalConfiguration.gaugeType > 0">
                            <div class="col-5 mb-2 align-self-center"><label class="form-label font-weight-bold">Кол-во
                                    {{ GaugeHaveAddr(terminalConfiguration?.gaugeType) ?
                                        'каналов' : 'уровнемеров' }}</label></div>
                            <div class="col-7 align-self-center"><input type="number" @keypress="onlyNumber" min="1"
                                    max="8" :disabled="loading" :value="terminalConfiguration.gauge_col"
                                    @input="change_gauge_col" class="form-control" required />

                            </div>
                        </div>
                        <div class="row mb-2" v-if="terminalConfiguration.gaugeType > 0">
                            <b-tabs v-model="active_tab" content-class="mt-2 px-2">
                                <b-tab v-for="(elem, index) in terminalConfiguration.gaugeChannels" :key="index"
                                    :title="index == active_tab ? `${GaugeHaveAddr(terminalConfiguration?.gaugeType) ? 'Канал ' : 'Уровнемер '}` + (index + 1).toString() : (index + 1).toString()"
                                    :active="index == 0">
                                    <div class="row mb-2 align-items-center">
                                        <div class="col-sm-5 col-4"><label class="form-label font-weight-bold">Адрес
                                                {{ GaugeHaveAddr(terminalConfiguration?.gaugeType) ?
                                                    'канала' : 'уровнемера' }}</label>
                                        </div>
                                        <div class="col-sm-7 col-8"><input type="number" @keypress="onlyNumber" min="0"
                                                :disabled="loading"
                                                v-model="terminalConfiguration.gaugeChannels[index].adr"
                                                class="form-control" required /></div>
                                    </div>
                                    <div class="row mb-2 align-items-center">
                                        <div class="col-sm-5 col-4"><label class="form-label font-weight-bold">Тип
                                                ГСМ</label>
                                        </div>
                                        <div class="col-sm-7 col-8">
                                            <!-- <bs-select v-model="terminalConfiguration.pumps[0].type" :disabled=" loading" @input="change_type_terminal" :options="AllPumpsTypesSelect" required>
                                            <option selected value=""   class="d-none">
                                                Выберите
                                                тип ТРК</option>
                                        </bs-select>-->
                                            <multiselect autocomplete="off" trackBy="text" valueProp="value"
                                                :disabled="loading" :searchable="true" locale="ru" :allowEmpty="true"
                                                placeholder="Выберите продукт" id="Product" :close-on-select="true"
                                                label="text" required
                                                v-model.number="terminalConfiguration.gaugeChannels[index].productID"
                                                :options="AllProductSelect">
                                                <template v-slot:nooptions>
                                                    <div
                                                        class="d-flex w-100 align-items-center justify-content-center my-2">
                                                        Нет элементов</div>
                                                </template>
                                                <template v-slot:noresults>
                                                    <div
                                                        class="d-flex w-100 align-items-center justify-content-center my-2">
                                                        Не найдено</div>
                                                </template>
                                            </multiselect>
                                            <!--  <bs-select :disabled="loading" v-model.number="terminalConfiguration.gaugeChannels[index].productID" :options="AllProductSelect" required>
                                                            <option selected disabled value="" class="d-none">
                                                                Выберите продукт</option>
                                                        </bs-select>-->
                                        </div>
                                    </div>
                                    <div v-if="GaugeHaveCalibrationTable(terminalConfiguration.gaugeType)"
                                        class="row mb-2">
                                        <div class="w-100 d-flex align-itemes-center justify-content-start">
                                            <button type="button" @click="ShowEditCalibrationTableModal()"
                                                class="btn  btn-smx btn-information ">
                                                Тарировочная таблица
                                            </button>
                                        </div>
                                    </div>

                                </b-tab>
                            </b-tabs>
                        </div>
                    </fieldset>
                </div>
            </form>
            <template #footer class="">
                <div class="d-flex align-items-center flex-wrap" style="gap:0.5rem">
                    <button :disabled="loading" type="submit" @click="CreateOrEditTerminal"
                        class="btn btn-primary text-center btn-sm align-items-center  flex-grow-1 flex-md-grow-0">
                        <span v-if="!loading">Сохранить изменения</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button v-if="edit" :disabled="loading || isConfigurationChanged" type="button"
                        @click="UpdateTerminalConfiguration" title="Создание команды обновления конфигурации"
                        class="btn btn-secondary text-center btn-sm align-items-center flex-grow-1 flex-md-grow-0"
                        style="color: #fff;">
                        <b-spinner title="Конфигурация на обновлениии" small class="me-1" v-if="isConfigurationChanged"
                            label="Маленький спиннер" type="grow"></b-spinner>
                        <span v-if="!loading">Обновить конфигурацию</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--Модальное изменения тарировочных таблиц карт-->
        <bs-modal centered id="EditCalibrationTableModal" :loading="loading" ref="EditCalibrationTableModal" hide-footer
            :title="`Тарировочная таблица  ${GaugeHaveAddr(terminalConfiguration?.gaugeType) ? 'канала' : 'уровнемера'} ` + (active_tab + 1)">


            <button class="btn btn-outline-success btn-sm btn-add" @click="CreateCalibrationLevel"
                :disabled="CheckDisabledCreateButtonCalibrationTable" title="Добавть уровень">
                <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить уровень

            </button>
            <b-table id="calibrationTable" :items="calibrationTable" :fields="fields_calibration" :sticky-header="true"
                :no-border-collapse="true" style="max-height:50vh" primary-key="level"
                :tbody-transition-props="transProps">
                <template #cell(index)="data">{{ data.index + 1 }}</template>

                <template #cell(level)="data">
                    <input type="number" min="0" @input="VerifyIdentity(data.index, $event.target.value, 'level')"
                        v-if="data.item.edit" class="form-control" v-model.number="editable_value_level">
                    <span v-if="!data.item.edit">{{ data.item.level }}</span>
                </template>

                <template #cell(volume)="data">
                    <input type="number" min="0" max="50000"
                        @input="VerifyIdentity(data.index, $event.target.value, 'volume')" v-if="data.item.edit"
                        class="form-control" v-model.number="editable_value_volume">
                    <span v-if="!data.item.edit">{{ data.item.volume }}</span>
                </template>
                <template #cell(edit_okey)="data">
                    <div class="actions justify-content-center gap-2" style="cursor:pointer">
                        <button @click="EditCalibrationLevel(data.item)"
                            :disabled="CheckDisabledEditButtonCalibrationTable" class="btn edit" v-if="!data.item.edit"
                            style=""><i class="fa fa-pencil fa-1x" title="Изменить"></i></button>
                        <button v-if="data.item.edit" class="btn add"
                            :disabled="verify || editable_value_level == null || editable_value_level == '' || editable_value_volume == '' || editable_value_volume == null"
                            type="button" @click="SaveCalibrationLevel(data.index)" title="Подтвердить">
                            <i class="fa  fa-check fa-1x" aria-hidden="true"></i>
                        </button>
                        <button type="button" v-if="data.item.edit" class="btn delete"
                            @click="CancelCalibrationLevel(data.index)" title="Отменить">
                            <i class="fa fa-times fa-1x" aria-hidden="true"></i>
                        </button>
                    </div>
                </template>
                <template #row-details="row">
                    <div class="invalid-feedback d-block">
                        <div v-if="error_calibration_level != null">{{ error_calibration_level }}</div>
                        <div v-if="error_calibration_volume != null">{{ error_calibration_volume }}</div>

                    </div>
                </template>
                <template #cell(delete)="data">
                    <div class="actions justify-content-center">

                        <button class="btn delete" v-if="!data.item.isRemoved"
                                            title="Удаление показания"   @click="calibrationTable.splice(data.index, 1)"><i
                                                class="fa fa-trash fa-1x"></i></button>
                     
                    </div>
                </template>
            </b-table>
            <div class="col-12 text-start">
                <button :disabled="CheckDisabledSaveButtonCalibrationTable" @click="SaveChangeCalibrationTable"
                    type="submit" class="btn btn-sm btn-primary">
                    <span>Сохранить</span>
                </button>
            </div>
        </bs-modal>
        <!--#endregion -->

    </div>

</template>
<script>
var ru = require("apexcharts/dist/locales/ru.json");
import Vue from 'vue';
import {
    feather
} from '@/main.js';
import {
    mapGetters
} from 'vuex'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const'
export default {

    data() {
        return {

            defaultStartDate: moment().startOf("day").subtract(1, 'days').format(),
            defaultEndDate: moment().endOf("day").format(),
            start: null,
            end: null,

            ///Бесконечное отображение результата заправки
            resultTimeoutInfinity: false,

            error_calibration_level: null,
            error_calibration_volume: null,

            ChartOptions: {

                chart: {
                    name: "История_изменения_объёма_топлива",
                    id: 'TankHistory',
                    height: 350,
                    locales: [ru],
                    defaultLocale: 'ru',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },

                tooltip: {
                    x: {
                        show: false,
                        format: 'dd MMM yyyy / HH:mm:ss',
                    },
                    enabled: true
                },

                noData: {
                    text: "Нет данных за указанный период",
                    align: 'center',
                    verticalAlign: 'middle',

                },/*
                title: {
                    text: 'История изменения топлива в резервуаре',
                    style: {
                        fontFamily: "Arial",
                    },
                    align: 'left', offsetX: -10,
                },
*/
                fill: {
                    colors: ['#1A73E8', '#B32824']
                },

                labels: [],

                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: "datetime"
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(3);
                        }
                    },
                    title: {
                        text: "Объём в литрах",


                    },
                }




            },
            series: [{
                name: 'Объём',
                data: []
            }],
            emptyText: "Нет терминалов. Добавьте новый",
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            active_tab: null,
            calibrationTable: [],
            currentGaugeData: null,

            verify: false,
            editable_value_level: null,
            editable_value_volume: null,
            emptyFilteredText: "Не найдено",
            fields_calibration: [{
                key: 'index',
                label: '№',
                sortable: false,
                thClass: textC_alignM,
                tdClass: textC_alignM
            },
            {
                key: 'level',
                label: 'Уровень',
                sortable: false,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {
                    "width": "140px"
                }
            },
            {
                key: 'volume',
                label: "Литры",
                sortable: false,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {
                    "width": "140px"
                }
            },
            {
                key: 'edit_okey',
                label: "",
                sortable: false,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {
                    "width": "80px"
                }
            },
            {
                key: 'delete',
                label: "",
                sortable: false,
                thClass: textC_alignM,
                tdClass: textC_alignM,
            },
            ],
            filtered_items: [],
            filter: "",
            fields: [{
                key: 'status',
                label: 'Статус',
                sortable: true,
                thClass: textC_alignM,
                tdClass: textC_alignM
            },
            {
                key: 'name',
                label: "Наименование",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {
                    width: "25%"
                }
            },
            {
                key: 'imei',
                label: "IMEI",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {
                    width: "10%"
                }
            },
            {
                key: 'blackList',
                label: "Заблокированные карты",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {
                    width: "25%"
                }
            },
            {
                key: 'organizationIds',
                label: "Подразделения",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {
                    width: "25%"
                }
            },
                // { key: 'actions', sortable: false, label: "", class: textC_alignM, thStyle: { width: "5%" } }
            ],
            perPage: 10,
            currentPage: 1,
            edit: null,
            modalDialogScrollable: true,
            //текущий эллемент коллапса
            current_collapse: null,
            //Для Терминалов//
            name: null, //Номер карты
            id: null, //GUID карты
            imei: null,
            product_HardwareID: null,
            empty_text: "Нет терминалов",
            interval: null,
            ///Чёрный список карть
            filterBlackList: "",
            selected_blockCards: [],
            selected_cards: [],
            ///Подразделения;
            filterOrganizations: "",
            selected_organizationsIds: [],
            selected_organizations: [],


            ///Водители
            selectedDrivers: [],
            indeterminateDriver: false,
            allDriverSelected: false,
            filterDrivers: "",

            sortArray: [{ text: 'Наименование', value: 'name', ask: true }, { text: 'IMEI', value: 'imei', ask: true }],
            sortBy: 'name',
            //Конфигурация
            defaultTerminalConfiguration:
            {
                adminPassword: null,
                keyboardRotation: 0,
                showResultTimeout: 10,
                useCarNumber: false,
                hourOffset: null,
                fullTankVolume: 50,
                gaugeAddr: 1,
                gaugeChannels: [],
                gaugeType: 0,
                //keyA: null,
                //keyB: null,
                pumps_col: 1,
                gauge_col: 0,
                fuels: [],
                pumps: [{
                    addr: 1,
                    type: null,
                    nozzles_col: 1,
                    nozzles: [{
                        adr: 1,
                        productID: null
                    },
                    ]
                },
                ],
            },
            terminalConfiguration: null,
            //Тип действия
            action: {
                type: null,
                method: null,
            },
            showStatuses: [],
            //типы трк
            selected_type: [],
        }
    },
    computed: {
        momentStart() { return this.start == null ? null : moment(this.start); },
        momentEnd() { return this.end == null ? null : moment(this.end); },
        TerminalsModel() {
            return this.AllTerminals.filter(a => !a.isRemoved).sort((a, b) => {
                let nameA = a[this.sortBy].toLowerCase();
                let nameB = b[this.sortBy].toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }).map(elem => { return Object.assign({}, elem, { showStatus: false }) })
        },
        ///Водители
        Drivers() {
            return this.AllDrivers.filter(a => !a.isRemoved).map(elem => {
                var text = null;
                var html = null;
                if (elem.pin != null) {

                    text = elem.name + `<br>Код: ${elem.pin} 
            <br>Карта: ${this.NumberSpace(elem.number)}`
                    html = text
                }
                else {
                    text = elem.name + `<br>Карта: ${this.NumberSpace(elem.number)}`

                    html = text
                }
                return Object.assign({}, elem, { text: text, html: html })
            })
        },
        FilteredOrganizations() {
            if (this.filterOrganizations == "" || this.filterOrganizations.length == 0)

                return this.OrganizationsTerminals(this.id)
            else return this.OrganizationsTerminals(this.id).filter(a => {
                if (a.text != null && a.text.toLowerCase().indexOf(this.filterOrganizations.toLowerCase()) > -1)
                    return true
                else return false;

            })
        },
        FilteredBlackList() {
            if (this.filterBlackList == "" || this.filterBlackList.length == 0)
                return this.BlackListCards(this.id)

            else return this.BlackListCards(this.id).filter(a => {
                if (a.holderName != null && a.holderName.toLowerCase().indexOf(this.filterBlackList.toLowerCase()) > -1)

                    return true;
                if (a.number != null && a.number.toLowerCase().indexOf(this.filterBlackList.split(" ").join("").toLowerCase()) > -1)
                    return true;
                else return false;

            })

        },

        FilteredDrivers() {
            if (this.filterDrivers == "" || this.filterDrivers.length == 0)
                return this.Drivers;
            else {
                return this.Drivers.filter(a => {
                    if (a.name.toLowerCase().indexOf(this.filterDrivers.toLowerCase()) > -1)

                        return true;


                    if (a.number != null && a.number.toLowerCase().indexOf(this.filterDrivers.split(' ').join('').toLowerCase()) > -1)
                        return true;
                    if (a.pin != null && a.pin.toLowerCase().indexOf(this.filterDrivers.toLowerCase()) > -1) return true;
                    else return false
                });
            }
        },
        ////
        ///Отображение параметра для версии
        CheckVisibleSoftwareParam20() {
            if (!this.edit) return true;
            if (this.softwareVersion == this.SoftwareVersionCheck.Error && this.isConfigurationChanged) return true;
            if (this.softwareVersion >= this.SoftwareVersionCheck.ParamVewrsion20) return true;
            return false;
        },
        CheckVisibleSoftwareParam25() {
            if (!this.edit) return true;
            if (this.softwareVersion == this.SoftwareVersionCheck.Error && this.isConfigurationChanged) return true;
            if (this.softwareVersion >= this.SoftwareVersionCheck.ParamVewrsion25) return true;
            return false;
        },
        softwareVersion() {
            return this.TerminalByGUID(this.id)?.softwareVersion ?? 0;
        },
        isConfigurationChanged() {
            return this.TerminalByGUID(this.id)?.isConfigurationChanged ?? false;
        },

        AllTerminalsFiltered() {
            if (this.filter.length < 3) { return this.TerminalsModel }
            else {
                return this.TerminalsModel.filter(el => {
                    var a = el.imei.split(' ').join('').toLowerCase().indexOf(this.filter.split(' ').join('').toLowerCase());
                    var b = el.name.split(' ').join('').toLowerCase().indexOf(this.filter.split(' ').join('').toLowerCase())
                    return a > -1 || b > -1
                })
            }
        },
        CheckDisabledSaveButtonCalibrationTable() {
            if (this.calibrationTable.find(el => el.edit == true) != undefined) return true;
            return false;
        },
        CheckDisabledCreateButtonCalibrationTable() {
            if (this.calibrationTable.length == 4096) return true;
            if (this.calibrationTable.find(el => el.edit == true) != undefined) return true;
            return false;
        },
        CheckDisabledEditButtonCalibrationTable() {
            if (this.calibrationTable.find(el => el.edit == true) != undefined) return true;
            return false;
        },
        AllOrganizationSelect() {
            return this.AllOrganizations.filter(t => t.isRemoved == false)?.map(element => {
                return {
                    text: element.name,
                    value: element.id,
                }
            }) ?? []
        },
        AllProductSelect() {
            return this.AllProducts?.filter(t => t.isRemoved == false)?.map(element => {
                return {
                    text: element.hardwareName,
                    value: element.hardwareID,
                }
            }) ?? []
        },
        rows() {
            if (this.filtered_items.length == 0 && this.filter == "")
                return this.AllTerminals.length
            else {
                return this.filtered_items.length;
            }
        },
        ...mapGetters([
            'AllDrivers',
            'DriversOnceLoaded',
            'SoftwareVersionCheck',
            'GaugeHaveAddr',
            'GaugeHaveCalibrationTable',
            'TimeZones',
            'KeyboardRotate',
            'AllTerminals',
            'TerminalByGUID',
            'TerminalsGetAreLoaded',
            'TerminalsOnceLoaded',
            'TerminalsGetAreLoading',
            'TerminalsGetHaveError',
            'TerminalsPostAreLoading',
            'TerminalsPostHaveError',
            'TerminalsPutAreLoading',
            'TerminalsPutHaveError',
            'TerminalsDeleteAreLoading',
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'OrganizationsPostAreLoading',
            'OrganizationsPostHaveError',
            'OrganizationsPutAreLoading',
            'OrganizationsPutHaveError',
            'OrganizationsDeleteAreLoading',
            'OrganizationsTerminals',
            'CardByGUID',
            'AllCards',
            'CardsOnceLoaded',
            'CardsGetAreLoading',
            'CardsGetHaveError',
            'CardsPostAreLoading',
            'CardsPostHaveError',
            'CardsPutAreLoading',
            'CardsPutHaveError',
            'CardsDeleteAreLoading',
            'BlackListCards',
            'WalletByGUID',
            'AllProducts',
            'ProductsOnceLoaded',
            'ProductByGUID',
            'ProductByhardwareID',
            'ProductsGetAreLoading',
            'ProductsGetHaveError',
            'AllGaugeTypesSelect',
            'AllPumpsTypes',
            'AllPumpsTypesSelect',
            'PumpsTypeHaveAddr',
        ]),
        isBusy() {
            if (this.CardsGetHaveError || this.ProductsGetHaveError || this.OrganizationsGetHaveError || this.TerminalsGetHaveError) {
                return false;
            } else {
                return (this.CardsOnceLoaded && this.OrganizationsOnceLoaded && this.ProductsOnceLoaded && this.TerminalsOnceLoaded) ? false : true;
            }
        },
        loading() {
            switch (this.action.method) {
                case 'get':
                    if (this.action.type === 'card') return this.CardsGetAreLoading;
                    if (this.action.type === 'organization') return this.OrganizationsGetAreLoading;
                    if (this.action.type === 'terminals') return this.TerminalsGetAreLoading;
                case 'post':
                    if (this.action.type === 'card') return this.CardsPotsAreLoading;
                    if (this.action.type === 'organization') return this.OrganizationsPotsAreLoading;
                    if (this.action.type === 'terminals') return this.TerminalsPotsAreLoading;
                case 'put':
                    if (this.action.type === 'card') return this.CardsPutAreLoading;
                    if (this.action.type === 'organization') return this.OrganizationsPutAreLoading;
                    if (this.action.type === 'terminals') return this.TerminalsPutAreLoading;
                case 'delete':
                    if (this.action.type === 'card') return this.CardsDeletereLoading;
                    if (this.action.type === 'organization') return this.OrganizationsDeleteAreLoading;
                    if (this.action.type === 'terminals') return this.TerminalsDeleteAreLoading;
                default:
                    return null
            }
        },
        NoBlockCardsSelect() {
            return this.AllCards.filter(item => {
                if (this.BlackListCards(this.id).find(el => el.id == item.id) == undefined)
                    return true;
                else return false
            }).map(element => {
                var text = element.holderName + ' ' + '(' + this.NumberSpace(element.number) + ')'
                return {
                    text,
                    value: element.id,
                }
            })
        },
        NoOrganizationsSelect() {
            return this.AllOrganizations.map(element => {
                return {
                    text: element.name,
                    value: element.id,
                }
            }).filter(item => {
                if (this.OrganizationsTerminals(this.id).find(el => el.text == item.text) == undefined)
                    return true;
                else return false
            })
        },
    },
    mounted() {
        feather.replace();
        this.initShowStatuses(this.AllTerminals)
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (isJustShown == true) {
                if (collapseId == this.current_collapse) this.current_collapse = null;
                this.current_collapse = collapseId

            }
            else {
                if (collapseId == this.current_collapse) this.current_collapse = null;

            }
        })
    },
    beforeDestroy() {
        if (this.interval != null) clearInterval(this.interval);
    },
    async created() {
        this.terminalConfiguration = this.defaultTerminalConfiguration
        if (!this.TerminalsOnceLoaded) {
            await this.$store.dispatch(TERMINALS_GET_REQUEST);
        }
        if (!this.ProductsOnceLoaded) {
            await this.$store.dispatch(PRODUCTS_GET_REQUEST);
        }
        if (!this.OrganizationsOnceLoaded) {
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        }
        if (!this.CardsOnceLoaded) {
            await this.$store.dispatch(CARDS_GET_REQUEST);
        }
        if (!this.DriversOnceLoaded) {
            await this.$store.dispatch(exp.DRIVERS_GET_REQUEST);
        }

        this.interval = setInterval(() => {
            this.$store.dispatch(TERMINALS_GET_REQUEST);
        }, 60000 * 2);
    },
    watch: {
        AllTerminals: {
            handler: function (newValue, oldValue) {
                this.initShowStatuses(newValue);

            }
        },
        selectedDrivers(newValue, oldValue) {
            // Обрабатывать изменения в отдельных флажках вкуса
            if (newValue.length === 0) {
                this.indeterminateDriver = false;
                this.allDriverSelected = false;
            } else if (newValue.length === this.Drivers.length) {
                this.allDriverSelected = true;
                this.indeterminateDriver = false
            } else {
                this.allDriverSelected = false;

                this.indeterminateDriver = true
            }
        },
        'terminalConfiguration.showResultTimeout': {
            handler: function (new_val, old_val) {
                if (this.check_8F(new_val)) this.resultTimeoutInfinity = true;
            }
        },
        resultTimeoutInfinity(new_val) {
            if (new_val) {
                this.terminalConfiguration.showResultTimeout = 0;
            }
        }
    },
    methods: {
        getHourlyRange(start, end) {
            const startDateTime = moment(start);
            const endDateTime = moment(end);
            const dateTimes = [];

            let currentDateTime = startDateTime.clone();
            while (currentDateTime.isSameOrBefore(endDateTime)) {
                dateTimes.push(currentDateTime.clone().format('YYYY-MM-DD HH:mm'));
                currentDateTime.add(1, 'hour');
            }

            return dateTimes;
        },
        initShowStatuses(newValue) {
            newValue.forEach(element => {
                if (this.showStatuses.find(a => element.id == a.id) == undefined)
                    this.showStatuses.push({ id: element.id, showStatus: false })
            });
        },
        ToggleStatus(item) {

            var id = item.id;

            let i = this.showStatuses.findIndex(p => p.id == item.id) ?? -1;
            if (i > 1);
            {
                var oldState = this.showStatuses[i].showStatus;
                this.showStatuses.splice(i, 1, { id: id, showStatus: !oldState })


            }

        },
        GetStatus(status) {
            var ret = [];
            for (var elem in status) {
                switch (elem) {
                    case 'mfrcState':
                        ret.push({ key: 'Статус MFRC', value: status[elem] ? 'исправен' : 'не исправен' })

                        break;
                    case 'flashState':
                        ret.push({ key: 'Статус flash-памяти', value: status[elem] ? 'исправна' : 'не исправна' })
                        break;

                    case 'gpsState':
                        if (status[elem])
                            ret.push({ key: 'Кол-во спутников', value: status[elem] })
                        else
                            ret.push({ key: 'Статус GPS', value: 'Поиск спутников...' })
                        break;
                    case 'pumpConfigured':
                        ret.push({ key: 'Кол-во ТРК', value: status[elem].filter(a => a == true).length })
                        break;
                    case 'levelGaugeConfigured':
                        ret.push({ key: 'Кол-во ДУТов', value: status[elem].filter(a => a == true).length })
                        break;
                    case 'btExists':
                        ret.push({ key: 'Bluetooth', value: status[elem] ? 'установлен' : 'не установлен' })
                        break;
                    default: continue;
                }


            }
            return ret;
        },
        momentFormat(item) { return moment(item).format("DD.MM.YY HH:mm:ss") },

        GetSoftwareVersion(id) {
            return this.TerminalByGUID(id)?.softwareVersion ?? 0;
        },
        toggleAllDrivers() {
            this.selectedDrivers = this.allDriverSelected ? this.Drivers.map(a => a.id) : [];
        },
        async EditDriversList() {
            this.action = {
                type: 'terminal',
                method: 'put'
            }
            let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
            terminal.driverList = this.selectedDrivers;
            await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                () => {
                    this.$refs['DriversTerminalModal'].success();
                }
            )
                .catch(
                    err => {
                        this.$refs['DriversTerminalModal'].error(err);
                    }
                )
        },
        ShowDriversTerminalModal(item) {
            this.id = item.id;
            this.selectedDrivers = item.driverList;
            this.$refs['DriversTerminalModal'].show();
        },
        async Refresh() {
            if (!this.ProductsOnceLoaded) {
                await this.$store.dispatch(PRODUCTS_GET_REQUEST);
            }
            if (!this.OrganizationsOnceLoaded) {
                await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
            }
            if (!this.CardsOnceLoaded) {
                await this.$store.dispatch(CARDS_GET_REQUEST);
            }
            if (!this.DriversOnceLoaded) {
                await this.$store.dispatch(exp.DRIVERS_GET_REQUEST);
            }
            this.$store.dispatch(TERMINALS_GET_REQUEST)
        },
        hexToDec(hex) { return parseInt(hex, 16) },
        decToHex(num) { return (parseInt(num)).toString(16).toUpperCase() },
        check_8F(elem) { return this.decToHex(elem) === "FFFFFFFF" },

        ShowСreateOrganizationModal() {
        },
        async UpdateTerminalConfiguration() {
            this.$store.dispatch(TERMINALS_UPDATE_CONF_POST_REQUEST, this.id).then().catch(err => this.$refs.CreateEditTerminalModal.error(err))
        },
        async setDateTime(e) {
            this.start = e.startDTime;
            this.end = e.endDTime;
            await this.GetChartData(this.active_tab)

        },
        LevelGaugeConfigured(item, index) {
            if (item?.levelGaugeConfigured != null) {
                return item?.levelGaugeConfigured[index];
            }
            else return false;

        },
        LevelGaugeLinkStatus(item, index) {
            if (item?.levelGaugeLinkStatus != null && item?.online) {

                return item?.online //&& item?.levelGaugeLinkStatus[index];
            }
            else return false;

        },
        async GetChartData(gauge) {
            await this.$store.dispatch(TANK_HISTORY_GET_REQUEST, { guid: this.id, start: this.momentStart?.toJSON(), end: this.momentEnd?.toJSON(), index: gauge }).then(resp => {

                if (resp.data.length == 0) {
                    this.ChartOptions.labels == this.getHourlyRange(this.start, this.end);
                }
                this.series[0].data = [];
                for (var i = 0; i < resp.data.length; i++) {
                    var elem = [];
                    var value = resp.data[i].item/*s[this.active_tab]*/.volume;
                    if (value == null) value = 0;
                    var date = moment(resp.data[i].utc).valueOf()

                    elem.push(date, value)
                    this.series[0].data.push(elem);
                }
                this.$refs.ChartsModal.LoadingFalse();

            }

            ).catch(err => {

                this.$refs.ChartsModal.LoadingFalse();
                this.$refs.ChartsModal.error(err);
            })

        },
        async ShowChartsModal(guid, gauge, data, product_HardwareID) {

            this.start = this.defaultStartDate;
            this.end = this.defaultEndDate;
            this.id = guid;
            this.product_HardwareID = product_HardwareID;
            this.currentGaugeData = data
            this.active_tab = gauge;
            this.$refs.ChartsModal.show();
            await this.GetChartData(gauge);
        },
        GetFullTankVolume(fullTankVolume) {
            if (Number(fullTankVolume) == 0) return "-"
            else return fullTankVolume + ' л.'
        },
        GetType(TYPE_NUMBER) {
            var type = this.AllPumpsTypes?.find(a => a.value == TYPE_NUMBER) ?? null;
            if (type == null || type == undefined)
                return "";
            else return type.text;

        },
        SaveChangeCalibrationTable() {
            this.terminalConfiguration.gaugeChannels[this.active_tab].calibrationTable.rows = this.calibrationTable.map(element => {
                return {
                    volume: element.volume,
                    level: element.level,
                }
            });
            this.$refs.EditCalibrationTableModal.hide();
            this.$refs['CreateEditTerminalModal'].show({
                vm: this,
                name: "EditCalibrationTableModal"
            })
        },
        VerifyIdentity(index, value, type) {
            var a = undefined;
            var b = undefined;
            this.error_calibration_level = null
            this.error_calibration_volume = null;

            if (type == "volume") {
                a = this.calibrationTable.find((element, ind) => {
                    return (parseInt(element.volume) == parseInt(value) && ind != index)
                });
            } else {
                a = this.calibrationTable.find((element, ind) => {
                    return (parseInt(element.volume) == parseInt(this.editable_value_volume) && ind != index)
                })
            }
            if (type == "level") {
                b = this.calibrationTable.find((element, ind) => {
                    return (parseInt(element.level) == parseInt(value) && ind != index)
                });
            }
            else {
                b = this.calibrationTable.find((element, ind) => {
                    return (parseInt(element.level) == parseInt(this.editable_value_level) && ind != index)
                });
            }
            if (b != undefined) this.error_calibration_level = `Значения уровней не могут быть идентичны`
            if (a != undefined) this.error_calibration_volume = "Значения объёма не могут быть идентичны";
            if (a == undefined && b == undefined) {
                this.calibrationTable[index]._showDetails = false;
                this.verify = false;
            } else {
                this.calibrationTable[index]._showDetails = true;
                this.verify = true;
            }





        },
        EditCalibrationLevel(element) {
            this.editable_value_level = element.level
            this.editable_value_volume = element.volume;
            element.edit = true;
        },
        SaveCalibrationLevel(index) {
            this.calibrationTable[index].level = this.editable_value_level;
            this.calibrationTable[index].volume = this.editable_value_volume;
            this.calibrationTable[index].edit = false;
            this.calibrationTable.sort(function (a, b) {
                return parseInt(a.level) - parseInt(b.level);
            });
            /*  for (var i = 0; i < this.calibrationTable.length; i++) {
                  this.calibrationTable[i].level = parseInt(i);
              }*/
        },
        CancelCalibrationLevel(index) {
            this.calibrationTable[index]._showDetails = false;

            if (this.calibrationTable[index].volume == null) this.calibrationTable.splice(index, 1)
            else {
                this.calibrationTable[index].edit = false
            };
        },
        CreateCalibrationLevel() {
            this.editable_value_volume = null;
            this.editable_value_level = null;

            /* var itemsArray = (this.calibrationTable.map(el => {
                 return parseInt(el.level)
             }))
             var max_level = Math.max(...itemsArray);*/
            this.calibrationTable.push({
                level: null,
                value: null,
                edit: true
            });
        },
        onFiltered(filteredItems) {
            this.filtered_items = filteredItems;
            this.currentPage = 1;
        },
        filterTable(row, filter) {
            if (filter.length == 0 || filter.length == 1) return true
            if (row.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.name.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            if (row.imei.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.imei.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            else return false
        },
        clear() {
            this.filter = "";
        },
        OffTime(elem) {
            //window.momentDurationFormatSetup();
            moment.locale('ru');
            var now = moment();
            var before = moment(elem);
            var text = moment.duration(now.diff(before)).format('Y[ лет.] D[ дн.] H[ ч.] m[ м.] s[ сек.]');
            var text1 = "Выкл. " + text + ". <br>c" + before.format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        change_gauge_type(event) {
            if (event == 0) {
                this.terminalConfiguration.gauge_col = 0;
                this.terminalConfiguration.gaugeChannels = [];
            } else {
                if (this.terminalConfiguration.gaugeChannels.length == 0) {
                    this.terminalConfiguration.gaugeAddr = this.GaugeHaveAddr(this.terminalConfiguration.gaugeType) ? 1 : 0;

                    this.terminalConfiguration.gauge_col = 1
                    var event = {
                        target: {
                            value: 1
                        }
                    };
                    this.change_gauge_col(event)
                }
            }
        },
        change_gauge_col(event) {
            if (event.target.value === "" || event.target.value === " " || event.target.value === undefined || event.target.value === null || parseInt(event.target.value) < 1) event.target.value = 1;
            if (parseInt(event.target.value) > 8) event.target.value = 8;
            if (event.target.value > this.terminalConfiguration.gauge_col) {
                if (this.terminalConfiguration.gaugeChannels.length > event.target.value) {
                    this.terminalConfiguration.gaugeChannels.splice(event.target.value, this.terminalConfiguration.gaugeChannels.length - event.target.value);
                    this.terminalConfiguration.gauge_col = Number(event.target.value)
                } else {
                    var k = this.terminalConfiguration.gaugeChannels.length;
                    var kol = event.target.value - this.terminalConfiguration.gauge_col;
                    for (var i = 1; i <= kol; i++) {
                        this.terminalConfiguration.gaugeChannels.push({
                            adr: k + 1,
                            productID: null,
                            calibrationTable: this.GaugeHaveCalibrationTable(this.terminalConfiguration.gaugeType) ? {
                                rows: [{
                                    "level": 0,
                                    "volume": 0
                                },
                                {
                                    "level": 4096,
                                    "volume": 10000
                                }],
                            } : null,
                        }
                        )
                        k++;
                    }
                    this.terminalConfiguration.gauge_col = Number(event.target.value)
                }
            } else {

                this.terminalConfiguration.gaugeChannels.splice(event.target.value, this.terminalConfiguration.gaugeChannels.length - event.target.value);
                this.terminalConfiguration.gauge_col = Number(event.target.value)

            }
            if (this.terminalConfiguration.gauge_col == 1 && this.terminalConfiguration.gaugeChannels.length == 0) {

                this.terminalConfiguration.gaugeChannels.push({
                    adr: 1,
                    productID: null,
                    calibrationTable: {
                        rows: [{
                            "level": 0,
                            "volume": 0
                        }, {
                            "level": 4096,
                            "volume": 10000
                        }],
                    }
                }
                )


            }
        },
        change_nozzles_col(event, numb) {
            if (event.target.value === "" || event.target.value === " " || event.target.value === undefined || event.target.value === null || parseInt(event.target.value) < 1) event.target.value = 1;
            if (parseInt(event.target.value) > 6) event.target.value = 6;
            if (event.target.value > this.terminalConfiguration.pumps[numb].nozzles_col) {
                if (this.terminalConfiguration.pumps[numb].nozzles.length > event.target.value) {
                    this.terminalConfiguration.pumps[numb].nozzles.splice(event.target.value, this.terminalConfiguration.pumps[numb].nozzles.length - event.target.value);
                    this.terminalConfiguration.pumps[numb].nozzles_col = Number(event.target.value)
                } else {
                    var k = this.terminalConfiguration.pumps[numb].nozzles.length;
                    var kol = event.target.value - this.terminalConfiguration.pumps[numb].nozzles_col;
                    for (var i = 1; i <= kol; i++) {
                        this.terminalConfiguration.pumps[numb].nozzles.push({
                            adr: k + 1,
                            productID: null
                        },
                        )
                        k++;
                    }
                    this.terminalConfiguration.pumps[numb].nozzles_col = Number(event.target.value)
                }
            } else {
                this.terminalConfiguration.pumps[numb].nozzles.splice(event.target.value, this.terminalConfiguration.pumps[numb].nozzles.length - event.target.value);
                this.terminalConfiguration.pumps[numb].nozzles_col = Number(event.target.value)
            }
        },
        change_nozzle(eventumb) {
        },
        /*
        change_type_terminal(event) {
        for (var i = 0; i < this.terminalConfiguration.pumps.length; i++)
        this.terminalConfiguration.pumps[i].type = Number(event)
        },*/
        change_type_terminal(event) {
            for (var i = 0; i < this.terminalConfiguration.pumps.length; i++)
                this.terminalConfiguration.pumps[i].type = Number(event)
        },
        change_pumps_col(event) {
            if (event.target.value === "" || event.target.value === " " || event.target.value === undefined || event.target.value === null || parseInt(event.target.value) < 1) {
                event.target.value = 1;
            }
            if (parseInt(event.target.value) > 4) event.target.value = 4;
            if (event.target.value > this.terminalConfiguration.pumps_col) {
                if (this.terminalConfiguration.pumps.length > event.target.value) {
                    this.terminalConfiguration.pumps.splice(event.target.value, this.terminalConfiguration.pumps.length - event.target.value);
                    this.terminalConfiguration.pumps_col = Number(event.target.value)
                } else {
                    var k = this.terminalConfiguration.pumps.length;
                    var kol = event.target.value - this.terminalConfiguration.pumps_col;
                    for (var i = 1; i <= kol; i++) {
                        this.terminalConfiguration.pumps.push({
                            addr: k + 1,
                            type: this.terminalConfiguration.pumps[0].type,
                            nozzles_col: 1,
                            nozzles: [{
                                adr: 1,
                                productID: null
                            },
                            ]
                        },
                        )
                        k++;
                    }
                    this.terminalConfiguration.pumps_col = Number(event.target.value)
                }
            } else {
                this.terminalConfiguration.pumps.splice(event.target.value, this.terminalConfiguration.pumps.length - event.target.value);
                this.terminalConfiguration.pumps_col = Number(event.target.value)
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },
        ShowEditCalibrationTableModal() {
            this.calibrationTable = this.terminalConfiguration.gaugeChannels[this.active_tab].calibrationTable.rows.map(element => {
                let itemsArray = Object.assign({}, element);
                itemsArray.edit = false;
                itemsArray._showDetails = false;
                return itemsArray;
            })
            this.$refs.CreateEditTerminalModal.hide();
            this.$refs['EditCalibrationTableModal'].show({
                vm: this,
                name: "CreateEditTerminalModal"
            });;
        },
        //////Действия с картой
        ShowCreateTerminalModal() {
            if (this.AllOrganizationSelect.length == 0) {
                this.$refs['CreateEditTerminalModal'].error("Список подразделений пуст");

            }
            else if (this.AllProductSelect.length == 0) {
                this.$refs['CreateEditTerminalModal'].error("Список продуктов пуст");

            }
            else {
                this.resultTimeoutInfinity = false;
                this.edit = false;
                this.name = null;
                this.selected_organizations = [];
                this.imei = null;
                this.terminalConfiguration = this.defaultTerminalConfiguration;
                this.terminalConfiguration.pumps[0].type = this.AllPumpsTypesSelect[0].value;
                this.resultTimeoutInfinity = false;
                this.id = null;
                this.$refs['CreateEditTerminalModal'].show();

            }
        },
        ShowEditModalTerminal(guid) {

            if (this.AllOrganizationSelect.length == 0) {
                this.$refs['CreateEditTerminalModal'].error("Список подразделений пуст");

            }
            else if (this.AllProductSelect.length == 0) {
                this.$refs['CreateEditTerminalModal'].error("Список продуктов пуст");

            }
            else {
                let element = JSON.parse(JSON.stringify(this.TerminalByGUID(guid)));
                this.edit = true;
                //  this.selected_organizations = [];
                this.imei = element.imei;
                this.name = element.name;
                this.id = element.id;
                this.selected_organizations = element.organizationIds
                /* var hourOffset = this.TimeZones.find(elem => elem.value == element.terminalConfiguration.hourOffset)
                 element.terminalConfiguration.hourOffset = hourOffset;*/
                element.terminalConfiguration.pumps_col = element.terminalConfiguration.pumps.length;
                /* var Type = this.AllPumpsTypesSelect.find(elem => elem.value == element.terminalConfiguration.pumps[0].type);
                 element.terminalConfiguration.pumps[0].type = Type;*/
                element.terminalConfiguration.pumps.map(element => {
                    element.nozzles_col = element.nozzles.length;
                    return element;
                })
                if (element.terminalConfiguration.gaugeChannels != null) {
                    element.terminalConfiguration.gauge_col = element.terminalConfiguration.gaugeChannels.length;
                } else {
                    element.terminalConfiguration.gauge_col = 0;
                    element.terminalConfiguration.gaugeChannels = [];
                }
                //  var gaugeType = this.AllGaugeTypesSelect.find(elem => elem.value == element.terminalConfiguration.gaugeType);
                //  element.terminalConfiguration.gaugeType = gaugeType;
                if (this.check_8F(element.terminalConfiguration.showResultTimeout)) {
                    element.terminalConfiguration.showResultTimeout = 0;
                    this.resultTimeoutInfinity = true;
                }
                else { this.resultTimeoutInfinity = false; }
                this.terminalConfiguration = element.terminalConfiguration
                //setTimeout( ()=>this.$refs['CreateEditTerminalModal'].show(), 1000);
                this.$refs['CreateEditTerminalModal'].show( /*{ vm: this, name: "EditCalibrationTableModal" }*/)
            }
        },
        async CreateOrEditTerminal() {
            var k;
            var f = false;
            for (var i = 0; i < this.terminalConfiguration.pumps.length; i++) {
                for (var j = 0; j < this.terminalConfiguration.pumps[i].nozzles.length; j++) {
                    if (this.terminalConfiguration.pumps[i].nozzles[j].productID == null || parseInt(this.terminalConfiguration.pumps[i].nozzles[j].adr) == 0) {

                        var id = 'numb' + (i + 1);
                        f = true
                        if (id != this.current_collapse) this.$root.$emit('bv::toggle::collapse', id);
                        break;
                    }
                }
                if (f == true) break;
            }
            var vm = this
            this.$nextTick(async function () {
                if (this.$refs.CreateOrEditTerminalForm.checkValidity() == false) this.$refs.CreateOrEditTerminalForm.reportValidity();
                else {
                    if (this.edit) {
                        this.action = {
                            type: 'terminal',
                            method: 'put'
                        }
                        let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
                        terminal.name = this.name;
                        terminal.id = this.id;
                        if (terminal.organizationIds.length == 0) terminal.organizationIds = this.selected_organizations
                        terminal.terminalConfiguration = JSON.parse(JSON.stringify((this.terminalConfiguration)))
                        if (this.resultTimeoutInfinity) {
                            terminal.terminalConfiguration.showResultTimeout = this.hexToDec("FFFFFFFF");
                        }

                        //    terminal.terminalConfiguration.pumps[0].type = terminal.terminalConfiguration.pumps[0].type.value;
                        //   terminal.terminalConfiguration.hourOffset = terminal.terminalConfiguration.hourOffset.value;
                        // terminal.terminalConfiguration.gaugeType = terminal.terminalConfiguration.gaugeType.value;
                        await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                            () => {

                                this.$refs['CreateEditTerminalModal'].success();
                            }
                        )
                            .catch(
                                err => {
                                    this.$refs['CreateEditTerminalModal'].error(err);
                                }
                            )
                    } else {
                        this.action = {
                            type: 'terminal',
                            method: 'post'
                        }
                        {
                            var selected_organizations = JSON.parse(JSON.stringify((this.selected_organizations)))
                            var terminalConfiguration = JSON.parse(JSON.stringify((this.terminalConfiguration)));
                            // terminalConfiguration.pumps[0].type = terminalConfiguration.pumps[0].type.id;
                            //  terminalConfiguration.hourOffset = terminalConfiguration.hourOffset.value;
                            // terminalConfiguration.gaugeType = terminalConfiguration.gaugeType.value;
                            this.$store.dispatch('TERMINALS_POST_REQUEST', {
                                name: this.name,
                                imei: this.imei,
                                blackList: [],
                                organizationIds: selected_organizations,
                                terminalConfiguration: terminalConfiguration,
                            })
                                .then(() => {
                                    this.$refs['CreateEditTerminalModal'].success();
                                })
                                .catch(
                                    err => {
                                        this.$refs['CreateEditTerminalModal'].error(err);
                                    })
                        }
                    }
                }
            })
        },
        ShowEditBlocklistModal(element) {
            this.id = element.id;
            this.selected_cards = [],
                this.blackList = [],
                this.$refs['EditBlocklistModal'].show()
        },
        ShowCreateBlockListModal() {
            this.$refs['EditBlocklistModal'].hide()
            this.selected_cards = [];
            this.$refs['CreateBlocklistModal'].show( /*{ vm: this, name: "EditBlocklistModal" }*/)
        },
        async AddBlackCards() {
            this.action = {
                type: 'terminal',
                method: 'put'
            }
            let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
            //var selected_cards = this.selected_cards.map(p => p.id)
            terminal.blackList = terminal.blackList.concat(this.selected_cards)
            await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                () => {
                    for (var i = 0; i < this.selected_cards.length; i++) {
                        this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, this.selected_cards[i])
                    }
                    this.selected_cards = [];
                    this.selected_blockCards = []
                    this.$refs['CreateBlocklistModal'].success();
                }
            )
                .catch(
                    err => {
                        this.selected_cards = [];

                        this.selected_blockCards = []
                        this.$refs['CreateBlocklistModal'].error(err);
                    }
                )
        },
        ShowDeleteBlockListModal() {
            this.$refs['EditBlocklistModal'].hide()
            //   this.block_card = this.CardByGUID(element);
            this.$refs['DeleteBlockListModal'].show( /*{ vm: this, name: 'EditBlocklistModal' }*/);
        },
        async DeleteBlackCards() {
            this.action = {
                type: 'terminal',
                method: 'put'
            }
            let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
            terminal.blackList = terminal.blackList.filter(element => {
                if (this.selected_blockCards.find(el => el == element) == undefined)
                    return true;
                else return false;
            })
            await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                () => {

                    for (var i = 0; i < this.selected_blockCards.length; i++) {
                        this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, this.selected_blockCards[i])
                    }
                    this.selected_blockCards = []
                    this.$refs['EditBlocklistModal'].success( /*"stop_hide"*/);
                }
            )
                .catch(
                    err => {
                        this.selected_blockCards = []
                        this.$refs['EditBlocklistModal'].error({
                            err: err,
                            /* action: "stop_hide"*/
                        });
                        // setTimeout(()=>this.$refs['EditBlocklistModal'].show(),3000)
                    }
                )
        },
        clearSelectedTags() {
            this.selected_cards = []
            this.selected_organizations = []
        },
        ShowEditOrganizationsModal(element) {
            this.id = element.id;
            this.selected_organizations = [];
            this.selected_organizationsIds = [];
            this.$refs['EditOrganizationsModal'].show()
        },
        ShowAddOrganizationsModal() {
            this.$refs['EditOrganizationsModal'].hide()
            this.selected_organizations = [];
            this.$refs['AddOrganizationsModal'].show( /*{ vm: this, name: "EditOrganizationsModal" }*/)
        },
        async AddOrganizations() {
            this.action = {
                type: 'terminal',
                method: 'put'
            }
            let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
            // var selected_organizations = this.selected_organizations.map(p => p.id)
            terminal.organizationIds = terminal.organizationIds.concat(this.selected_organizations)
            await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                () => {
                    this.selected_organizationsIds = []
                    this.$refs['AddOrganizationsModal'].success();
                }
            )
                .catch(
                    err => {
                        this.selected_organizationsIds = []
                        this.$refs['AddOrganizationsModal'].error(err);
                    }
                )
        },
        ShowDeleteOrganizationsModal() {
            this.$refs['EditOrganizationsModal'].hide()
            //   this.block_card = this.CardByGUID(element);
            this.$refs['DeleteOrganizationsModal'].show( /*{ vm: this, name: "EditOrganizationsModal" }*/);
        },
        async DeleteOrganizations() {
            this.action = {
                type: 'terminal',
                method: 'put'
            }
            let terminal = JSON.parse(JSON.stringify(this.TerminalByGUID(this.id)));
            terminal.organizationIds = terminal.organizationIds.filter(element => {
                if (this.selected_organizationsIds.find(el => el == element) == undefined)
                    return true;
                else return false;
            })
            await this.$store.dispatch('TERMINALS_PUT_REQUEST', terminal).then(
                () => {
                    this.selected_organizationsIds = []
                    this.$refs['EditOrganizationsModal'].success( /*"stop_hide"*/);
                }
            )
                .catch(
                    err => {
                        this.selected_organizationsIds = []
                        this.$refs['EditOrganizationsModal'].error({
                            err: err,
                            /*action: "stop_hide" */
                        });
                    }
                )
        },
    },
}
</script>
<style>
.multiselect-sort {
    min-height: 25px !important;
    max-height: 25px;
    width: 180px !important;
}

.terminal-btn {
    cursor: pointer;

    transition: all 0.25s ease;
    position: inherit !important;
}

.terminal-btn:hover {
    background: #f7f7f7;

}



.terminal-body__row {
    border: .3px solid #5252524d;
}

.terminal-body__row--break-all {
    word-break: break-all;
}

.terminal-body__row--uppercase {
    text-transform: uppercase;
}

.terminal-body__row .col>* {
    flex-grow: 1;
}

.terminal-body__row .col+.col {
    border-left: .3px solid #5252524d;
}

.levelGaugeSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.CreateOrEditTerminalForm .form-label {
    line-height: 1.5;
}

.card-body-terminals {
    overflow-y: auto;
    max-height: 70vh;
}

.terminal {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 4%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}


.terminal .terminal-header {
    display: flex;
    justify-content: space-between;
    gap: 5px
}

.terminal .terminal-header-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* с многоточием в конце */
    display: flex;
    align-items: center;
}

.terminal .terminal-header .terminal-header-title {
    font-size: 1.2rem;

}

.terminal-header-title {
    transition: background-color 0.1s ease-out;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.terminal-header-title:hover {
    border-radius: 5px 5px 0px 0px;
    background-color: #ffffff;
    color: #000;
    left: 0;
    width: 100% !important;
    white-space: normal !important;
    position: absolute;
    padding: 5px;

}

/*---------------card_custom--------------*/
.terminal .card-body {
    padding: 5px 10px 10px 10px !important;
}

.terminal .card-header {
    min-height: 40px;
}

/*---------------card_custom--------------*/
.main__content__1 {
    text-transform: uppercase;
    word-break: break-all;
    border: .5px solid #5252524d;
    ;
}

.main__content__2 {
    text-transform: uppercase;
    border: .5px solid #5252524d;
}



.terminal-text {
    font-size: .7rem;
    /*box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);*/
}




/*Card-terminal*/
.t-o-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



.circle-transition {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: #BFC0C0;
    position: absolute;
    transition: all .5s ease-Out;
    top: 20px;
    left: 70px;
}


.terminal__status {
    left: 0;
    height: calc(100% - 40px);
    padding: 10px;
    background: rgb(239 239 239);
    top: 40px;
    width: 100%;
    overflow-y: auto;

}
</style>