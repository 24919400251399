<template>
    <div class="input-group">
        <span class="input-group-text"><b-icon icon="calendar2-date" /></span>
        <input ref="inputDR" type="text" :id="id" :disabled="disabled" class="form-control rdp-range-date"
            autocomplete="off" placeholder="Выберите дату" @keydown="$event.preventDefault()" />
        <button v-if="showClearButton && this.start != null" @click="clearDate()"
            class="input-group-text">Очистить</button>

    </div>
</template>
<script>
import { feather } from '@/main.js';
import { componentsPlugin } from 'bootstrap-vue';
export default {
    props:
    {
        id: {
            type: String,
            required: true,
            default: "data-range"
        },
        timePicker: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            required: false,
            default: "datetimes"
        },
        showClearButton: {
            type: Boolean,
            default: false,
        },
        singleDatePicker: {
            type: Boolean,
            default: false,
        },
        maxDate: {
            default: moment().endOf("day"),
        },
        minDate: {
            default: undefined,
        },
        autoApply: {
            default: false,
        },
        autoUpdateInput: {
            default: true,
        },
        disabled: { default: false, type: Boolean },
        start: { type: Object, default: () => moment().subtract(6, 'days').startOf("day") },
        end: { type: Object, default: () => moment().endOf("day"), }
    },
    mounted() {
        feather.replace();
        var self = this;
        $('#' + this.id).daterangepicker({
            opens: 'left',
            singleDatePicker: this.singleDatePicker,
            showDropdowns: true,
            drops: "down",
            cancelButtonClasses: "btn-danger",
            timePicker: this.timePicker,
            startDate: this.start == null ? undefined : this.start,
            startDate: this.end == null ? undefined : this.end,
            minDate: this.minDate,
            // minDate: moment().subtract(20, 'years'),
            maxDate: this.maxDate,
            timePicker24Hour: true,
            timePickerSeconds: true,
            autoUpdateInput: this.autoUpdateInput,
            autoApply: this.autoApply,
            ranges: this.singleDatePicker ? undefined : {
                'Сегодня': [moment().startOf("day"), moment()],
                'Вчера': [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf("day")],
                'Последние 7 дней': [moment().subtract(6, 'days').startOf("day"), moment()],
                'Последние 30 дней': [moment().subtract(29, 'days').startOf("day"), moment()],
                'Текущий месяц': [moment().startOf('month'), moment()],
                'Предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            linkedCalendars: false,
            locale: {
                format: this.timePicker ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY",
                separator: " -> ",
                applyLabel: "Выбрать",
                cancelLabel: "Отменить",
                fromLabel: "С",
                toLabel: "По",
                customRangeLabel: "Выбрать интервал",
                weekLabel: "W",
                daysOfWeek: [
                    "Вс",
                    "Пн",
                    "Вт",
                    "Ср",
                    "Чт",
                    "Пт",
                    "Сб",

                ],
                monthNames: [
                    "Январь",
                    "Февраль",
                    "Март",
                    "Апрель",
                    "Май",
                    "Июнь",
                    "Июль",
                    "Август",
                    "Сентябрь",
                    "Октябрь",
                    "Ноябрь",
                    "Декабрь"
                ],
                firstDay: 1
            },
        }).on("apply.daterangepicker", function (e) {
            var picker = $(e.target).data('daterangepicker');
            if (self.singleDatePicker) {
                picker.element.val(picker.startDate.format(picker.locale.format));

            }
            else {
                picker.element.val(picker.startDate.format(picker.locale.format) + ' -> ' + picker.endDate.format(picker.locale.format));

            }
            self.updateTime(
                {
                    startDTime: picker.startDate.format(),
                    endDTime: picker.endDate.format()
                }
            )
        });;
        if (!this.autoUpdateInput && this.start != null) {
            if (this.singleDatePicker) {
                $('#' + this.id).data('daterangepicker').setStartDate(this.start);
                $('#' + this.id).data('daterangepicker').setEndDate(this.start);
            }
            else {
                $('#' + this.id).data('daterangepicker').setStartDate(this.start);
                $('#' + this.id).data('daterangepicker').setEndDate(this.end);
            }
            $('#' + this.id).trigger('apply.daterangepicker')
        }
    },
    methods: {
        clearDate() {
            this.$refs.inputDR.value = "";
            this.updateTime(
                {
                    startDTime: null,
                    endDTime: null,
                });
        },
        updateTime(e) { this.$emit('UpdateDT', e) }
    },
    /*
        destroyed: function () {
            $('#' + this.id)
                .off()
                .daterangepicker("destroy");
        }*/
}
</script>
