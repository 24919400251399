import axios from 'axios';
import indDB from '@/helpers/IndexedDB';

const API = axios.create({
    //baseURL: 'https://petrol.amsy.ru/api/',
    baseURL: '/api',
    timeout: 10000,
});
API.interceptors.request.use(
    async config => {
        let token = await indDB.getUserToken();
        if (token) {
            let href = window.location.href;
            if (token.role == "admin") {
                if (href.indexOf('admin') > -1) {
                    config.headers['Authorization'] = 'Bearer ' + token.jwt;
                }
                else {
                    let token_magic = await indDB.getMagicToken();
                    if (token_magic) {
                        config.headers['Authorization'] = 'Bearer ' + token_magic.jwt;
                    }
                }
            }
            else {
                config.headers['Authorization'] = 'Bearer ' + token.jwt;
            }
            config.headers['Content-Type'] = 'application / json';
        }
        return config;
    },
    error => {
        Promise.reject(error)
})
export default API;