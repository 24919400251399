/*Vue.component('header-panel', {*/
<template>
  <section>
    <div class="header-section">
      <div class="container-fluid">
        <div class="row rowHeader justify-content-between align-items-center">
          <!-- Логотип шапки-->
          <div class="header-logo col-auto  d-flex">
            <a>
              <span class="logo-default text-center">
                <img src="@/assets/images/logo/logo_2.png" id="AMS_logo" style="max-width: 80px;" alt="Logo Default" />
              </span>
            </a>
          </div>
          <!-- Логотип шапки (конец)-->
          <!-- Правая часть панели-->
          <div class="header-right flex-grow-1 col-auto">
            <div class="row     justify-content-between align-items-center">
              <!-- Кнопка боковой панели и поиск (старт) -->
              <div class="d-flex col-auto">
                <!-- Кнопка панели -->
                <button class="sidenav-toggle  " @click="SidenavTogle"><i data-feather="menu"></i></button>
                <!-- SideNav Toggle End -->
                <!-- Поиск (начало) -->
                <div class="header-search" v-if="showForAdmin">
                  <button class="header-search-toggle d-flex d-md-none"><i data-feather="search"></i></button>
                  <div class="header-search-form">
                    <form action="#" @submit="preventDefault()">
                      <input type="text" placeholder="Поиск..." />
                      <button class="search-submit"><i data-feather="search"></i></button>
                    </form>
                  </div>
                </div>
                <!--    Поиск (конец) -->
              </div>
              <!--Кнопка боковой панели и поиск (конец) -->
              <!-- Действия панели (начало) -->
              <div class="col-auto">
                <ul class="header-right-action nav">
                  <!-- Здесь были уведомления-->
                  <!-- Message Start -->
                  <li class="nav-item dropdown message" v-if="showForAdmin" @click="ShowDropdownMessages">
                    <a class="nav-link" data-bs-toggle="dropdown">
                      <i data-feather="message-square"></i>
                      <span class="badge badge-dot badge-success">badge</span>
                    </a>
                  </li>
                  <!-- Message End -->
                  <!-- Пользователь начало -->
                  <li class="nav-item dropdown user" @click="ShowDropdownProfile">
                    <a class="nav-link" href="#">
                      <div class="d-none d-sm-flex align-items-center">
                        <i class="far fa-user"></i>

                        <div class="name d-flex flex-column">
                          <span class="text-end">{{ user_role == 'user' ? CurrentUserName : 'SuperAdmin' }}</span>
                          <b-skeleton width="100px"
                            v-if="user_role == 'user' && (CurrentUserName == null || CurrentUserName == '')"></b-skeleton>
                          <span v-if="user_role == 'admin' && CurrentUserName != null" class="fw-normal text-end"> (Вход
                            под: {{ CurrentUserName }})</span>
                        </div>
                      </div>









                      <span class="open-icon"></span>
                    </a>
                    <div class="dropdown-menu menu-user dropdown-menu-right" style="top:80% !important">
                      <div class="dropdown-item  d-sm-none d-flex align-items-center">
                        <!-- <i class="far fa-user"></i>-->
                        <div class="name d-flex flex-column flex-grow-1">
                          <span>{{ user_role == 'user' ? CurrentUserName : 'SuperAdmin' }}</span>
                          <span v-if="user_role == 'admin' && CurrentUserName != null" class="fw-normal"> (Вход под: {{
                            CurrentUserName }})</span>
                        </div>
                      </div>
                      <hr class="d-sm-none">
                      <div v-if="user_role == 'admin'" class="dropdown-item" style="cursor:pointer" @click="ShowChangeSMTPModal()">
                        <i data-feather="mail"></i>
                        Настройки SMTP
                      </div>
                      <hr v-if="user_role == 'admin'">
                      <router-link :to="{ name: 'Profile' }" class="dropdown-item" href="#"><i data-feather="user"></i>
                        Профиль</router-link>
                      <a class="dropdown-item" @click="logout()"><i data-feather="log-out"></i>Выйти</a>
                    </div>
                  </li>
                  <!--Пользователь конец -->
                </ul>
              </div>
              <!-- Действия панели (конец) -->
            </div>
          </div>
          <!-- Правая часть панели (конец) -->
        </div>
      </div>



      <!-- #region modals -->
      <!--********************************************************** Создание / Изменение клиента   *******************************************-->
      <!-- #endregion -->

    </div>
    <bs-modal centered id="ChangeSMTPModal" ref="ChangeSMTPModal" title="Настройки SMTP">
      <form ref="ChangeSMTPForm" id="ChangeSMTPForm" @submit.prevent="ChangeSMTP">
        <div v-if="!SuperAdminGetError" class="row align-items-center">
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">URL
                сервера</label></div>
            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                v-model="smtpCredentialsEdit.url" class="form-control" placeholder="Введите URL сервера" required />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Имя
                пользователя</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                v-model="smtpCredentialsEdit.login" class="form-control" placeholder="Ввведите имя пользователя"
                required />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Пароль</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><input type="password" :disabled="loading"
                v-model="smtpCredentialsEdit.password" class="form-control" placeholder="Ввведите пароль" required />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Тема письма о
                низком балансе</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                v-model="smtpCredentialsEdit.balanceWarningSubjectTemplate" class="form-control"
                placeholder="Введите тему письма" required />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Содержимое письма
                о низком балансе</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><textarea :disabled="loading" rows="7" style="height: auto;"
                v-model="smtpCredentialsEdit.balanceWarningBodyTemplate" class="form-control"
                placeholder="Ввведите cодержание письма" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Тема письма об
                отключении</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                v-model="smtpCredentialsEdit.outOfServiceSubjectTemplate" class="form-control"
                placeholder="Введите тему письма" required />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Содержимое письма
                об отключении</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><textarea style="height: auto;" :disabled="loading" rows="7"
                v-model="smtpCredentialsEdit.outOfServiceBodyTemplate" class="form-control"
                placeholder="Ввведите cодержание письма" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 col-12 align-self-center"><label class="form-label font-weight-bold">Email
                тех.поддержки</label>
            </div>
            <div class="col-sm-8 col-12 align-self-center"><input type="email" :disabled="loading"
                v-model="smtpCredentialsEdit.contactEmail" class="form-control" placeholder="Ввведите Email" required />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-danger">Ошибка загрузки данных, обновите страницу</div>
        </div>
      </form>
      <template #footer>
        <div class="col-12 text-start">
          <button form="ChangeSMTPForm" :disabled="loading || SuperAdminGetError" type="submit"
            class="btn btn-sm btn-primary text-center align-items-center">
            <span v-if="!loading">Сохранить</span>
            <span v-else>
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              <span>&nbsp; Загрузка...</span>
            </span>
          </button>
        </div>
      </template>
    </bs-modal>
  </section>
</template>
<script>
import { feather } from '@/main.js';
import indDB from '@/helpers/IndexedDB'
import API from '@/store/axios';

import { mapGetters } from 'vuex';
import { USERS_CURRENT_GET_REQUEST } from '@/helpers/actions';
export default {
  data: function () {
    return {
      date: new Date().toLocaleDateString(),
      fio: 'Иванов Иван Иванович',
      name: null,
      auth_of: null,
      user_role: "user",
      smtpCredentials: null,
      smtpCredentialsDefault: {
        "url": null,
        "login": null,
        "password": null,
        "balanceWarningSubjectTemplate": null,
        "balanceWarningBodyTemplate": null,
        "outOfServiceSubjectTemplate": null,
        "outOfServiceBodyTemplate": null,
        "contactEmail": null,
      },
      smtpCredentialsEdit: {
        "url": null,
        "login": null,
        "password": null,
        "balanceWarningSubjectTemplate": null,
        "balanceWarningBodyTemplate": null,
        "outOfServiceSubjectTemplate": null,
        "outOfServiceBodyTemplate": null,
        "contactEmail": null,
      },
      SuperAdminGetError: false,
      loading: false,
    };
  },
  mounted() {

    ///Получение данных SMTP

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  watch: {
    showForAdmin(new_val) { if (new_val) { setTimeout(() => feather.replace(), 1000) } }
  },
  computed: {
    ...mapGetters(['CurrentUserName']),
    showForAdmin() { return this.name == 'OOO \"АМС\"' || this.name == "Admin" }
  },
  async created() {
    feather.replace();
    var token;
    let user_id = "";
    let user_role = "";
    token = await indDB.getUserToken();
    if (token != null) {
      user_id = token.userName;
      user_role = token.role;
    }
    this.user_role = user_role;
    if (user_role == "admin") {
      API({ url: "Superadmin", method: "GET" })
        .then(resp => {
          this.smtpCredentials = resp.data?.data?.smtpCredentials;
          this.SuperAdminGetError = false;
        })
        .catch(err => {
          this.smtpCredentials = false;
          this.SuperAdminGetError = true;
        });



      if (this.$route.path.split('/')[1] == "home") {
        token = await indDB.getMagicToken();
        if (token != null) {
          await this.$store.dispatch(USERS_CURRENT_GET_REQUEST, token.userName)

        }
      }

    } else {
      await this.$store.dispatch(USERS_CURRENT_GET_REQUEST, user_id);
    }
  },
  methods: {
    ShowChangeSMTPModal() {
      if (this.smtpCredentials != null) {
        this.smtpCredentialsEdit = Object.assign({}, this.smtpCredentials)
      }
      else {
        this.smtpCredentialsEdit = Object.assign({}, this.smtpCredentialsDefault)

      }

      this.$refs.ChangeSMTPModal.show();
    },

    async ChangeSMTP() {
      this.loading = true;
      await API({ url: "Superadmin/ChangeSMTPConfig", method: "PUT", data: this.smtpCredentialsEdit })
        .then(resp => {
          this.smtpCredentials = resp.data?.data?.smtpCredentials;

          this.$refs.ChangeSMTPModal.success();
        })
        .catch(err => {
          this.$refs.ChangeSMTPModal.error(err);
        });
      this.loading = false;

    },
    handleClickOutside(e) {
      if (e.target !== '.sidenav-toggle' && !e.target.closest('.sidenav-toggle, .sidenav-section')) {
        document.querySelector(".main-wrapper")?.classList.remove('sidenav-open')
      }
      if (e.target !== '.header-right-action' && !e.target.closest('.header-right-action')) {
        document.querySelector(".menu-user")?.classList.remove('show')
      }
    },
    ShowDropdownMessages() {
    },
    ShowDropdownProfile() {
      document.querySelector(".menu-user")?.classList.toggle('show')
    },
    SidenavTogle() {

      document.querySelector(".main-wrapper")?.classList.toggle('sidenav-open')
    },
    async logout() {
      this.$store.dispatch('AUTH_LOGOUT')
    }
  },
}
</script>
<style scoped>
.dropdown-menu {
  top: -10rem !important;
}

.open-icon {
  content: "";
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  border-top: 4.8px solid;
  border-right: 4.8px solid transparent;
  border-bottom: 0;
  border-left: 4.8px solid transparent;
}

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Описание стилей Hider'a!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

.header-section {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  height: 80px;
  background-color: #FFF;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15)
}


@media only screen and (max-width: 767px) {
  .header-section {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 42px
  }
}

.header-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 230px;
  min-width: 230px;
  height: 80px;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #E9E9EF
}

.header-logo a {
  position: relative
}

.header-logo a img {
  max-width: 100%
}

.header-logo a img.light {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0
}

.header-logo a .logo-icon {
  display: none
}

@media only screen and (min-width: 768px) and (max-width: 1400px),
only screen and (max-width: 767px) {
  .header-logo {
    width: auto;
    min-width: auto
  }

}

@media only screen and (max-width: 767px) {
  .header-logo {
    height: 42px;
    padding-right: 15px;
    padding-left: 15px
  }

}

.header-logo-icon .header-logo a .logo-icon {
  display: block
}

.header-logo-icon .header-logo a .logo-default {
  display: none
}

.header-logo-light .header-logo a img {
  visibility: hidden;
  opacity: 0
}

.header-logo-light .header-logo a img.light {
  visibility: visible;
  opacity: 1
}

.header-right {
  padding-right: 30px;
  padding-left: 30px
}

@media only screen and (max-width: 767px) {
  .header-right {
    padding-right: 15px;
    padding-left: 15px
  }
}

.sidenav-toggle {
  margin-right: 30px;
  padding: 0;
  color: var(--bs-primary);
  border: none;
  background-color: transparent;
  display: none;
}

.sidenav-toggle i {
  font-size: 20px
}

.sidenav-toggle svg.feather {
  width: 20px;
  height: 20px
}

@media only screen and (max-width: 767px) {
  .sidenav-toggle {
    margin-right: 20px;
  }

  .sidenav-toggle i {
    font-size: 16px
  }
}

@media only screen and (max-width: 1400px) {
  .sidenav-toggle {
    display: block;
  }
}

.header-search .header-search-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 0;
  color: var(--bs-primary);
  border: none;
  background-color: transparent
}

.header-search .header-search-toggle i {
  font-size: 18px
}

.header-search .header-search-toggle svg.feather {
  width: 18px;
  height: 18px
}

.header-search .header-search-toggle:hover {
  color: var(--bs-blue)
}

.header-search .header-search-form {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s
}

.header-search .header-search-form form {
  position: relative;
  padding-left: 33px
}

.header-search .header-search-form form input[type="text"] {
  width: 200px;
  padding: 0;
  letter-spacing: 0.5px;
  color: var(--bs-primary);
  border: none;
  background-color: transparent
}

.header-search .header-search-form form input[type="text"]::-webkit-input-placeholder {
  color: var(--bs-primary)
}

.header-search .header-search-form form input[type="text"]:-moz-placeholder {
  color: var(--bs-primary)
}

.header-search .header-search-form form input[type="text"]::-moz-placeholder {
  color: var(--bs-primary)
}

.header-search .header-search-form form input[type="text"]:-ms-input-placeholder {
  color: var(--bs-primary)
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .header-search .header-search-form form input[type="text"] {
    width: 130px
  }


}

.header-search .header-search-form form .search-submit {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--bs-primary);
  border: none;
  background-color: transparent
}

.header-search .header-search-form form .search-submit i {
  font-size: 18px
}

.header-search .header-search-form form .search-submit svg.feather {
  width: 18px;
  height: 18px
}

.header-search .header-search-form form .search-submit:hover {
  color: var(--bs-blue)
}

@media only screen and (max-width: 767px) {
  .header-search .header-search-form {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;
    padding: 20px 30px;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    background-color: #FFF
  }

  .header-search .header-search-form form input[type="text"] {
    width: 100%
  }
}

.header-search.open .header-search-form {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.header-right-action {
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.header-right-action .nav-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
  margin-top: 0
}

@media only screen and (max-width: 767px) {
  .header-right-action .nav-item {
    position: static;
    height: 42px;
  }
}

.header-right-action .nav-item+.nav-item {
  margin-left: 30px
}

@media only screen and (max-width: 767px) {
  .header-right-action .nav-item+.nav-item {
    margin-left: 20px
  }
}

.header-right-action .nav-item .nav-link {
  position: relative;
  padding: 0
}

.header-right-action .nav-item:not(.user) .nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--bs-primary);
  border-radius: 12px;
  background-color: rgba(32, 36, 66, 0.15)
}

.header-right-action .nav-item:not(.user) .nav-link .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.header-right-action .nav-item:not(.user) .nav-link .icon svg {
  width: 18px
}

.header-right-action .nav-item:not(.user) .nav-link .badge {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s
}

.header-right-action .nav-item:not(.user) .nav-link:hover {
  color: #FFF;
  background-color: var(--bs-blue)
}

.header-right-action .nav-item:not(.user) .nav-link:hover .badge {
  -webkit-transform: translateY(-3px) scale(1.25);
  -ms-transform: translateY(-3px) scale(1.25);
  transform: translateY(-3px) scale(1.25)
}

@media only screen and (max-width: 767px) {
  .header-right-action .nav-item:not(.user) .nav-link {
    width: 32px;
    height: 32px
  }

  .header-right-action .nav-item:not(.user) .nav-link .icon i {
    font-size: 16px
  }
}

.header-right-action .nav-item.user .nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.header-right-action .nav-item.user .nav-link .name {
  font-weight: 600;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .header-right-action .nav-item.user .nav-link .name {
    margin: 0 10px
  }
}

@media only screen and (max-width: 479px) {
  .header-right-action .nav-item.user .nav-link .name {
    margin: 0 5px
  }
}

.header-right-action .nav-item.user .nav-link .thumbnail {
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 12px
}

.header-right-action .nav-item.user .nav-link .thumbnail img {
  width: 100%
}

@media only screen and (max-width: 767px) {
  .header-right-action .nav-item.user .nav-link .thumbnail {
    width: 32px;
    height: 32px
  }
}

.header-right-action .nav-item.show:not(.user) .nav-link {
  color: #FFF;
  background-color: var(--bs-blue)
}

.header-right-action .nav-item.show:not(.user) .nav-link .badge {
  -webkit-transform: translateY(-3px) scale(1.25);
  -ms-transform: translateY(-3px) scale(1.25);
  transform: translateY(-3px) scale(1.25)
}

.header-right-action .nav-item.message .dropdown-menu {
  width: 300px
}

.header-right-action .nav-item.message .dropdown-item .media .media-body {
  padding-left: 10px
}

.header-right-action .nav-item.message .dropdown-item .media .media-body .media-title {
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.header-right-action .nav-item.message .dropdown-item .media .media-body .media-title span {
  font-weight: 400;
  margin-left: 10px;
  color: var(--bs-primary)
}

.header-right-action .nav-item.message .dropdown-item .media .media-body .media-text {
  font-size: 13px;
  color: var(--bs-primary)
}

.header-right-action .nav-item.notification .dropdown-menu {
  width: 300px
}

.header-right-action .nav-item.notification .dropdown-item .media .media-body {
  padding-left: 10px
}

.header-right-action .nav-item.notification .dropdown-item .media .media-body .media-title {
  font-size: 14px;
  font-weight: 500
}

.header-right-action .nav-item.notification .dropdown-item .media .media-body .media-title span {
  font-weight: 600
}

.header-right-action .nav-item.notification .dropdown-item .media .media-body .media-text {
  font-size: 12px;
  color: var(--bs-primary)
}
</style>